import { Box, Link, useTheme } from "@mui/material";
import { useOpenTaskDrawer } from "../hooks";
import { memo, useEffect, useState } from "react";

interface Props {
  href?: string;
  taskId?: number;
  value: string | Date;
}

export const ReportLink = memo(({ href, value, taskId }: Props) => {
  const { palette } = useTheme();
  const openTask = useOpenTaskDrawer();
  const [valueString, setValueString] = useState<string>("");
  const openDrawer = () => {
    if (taskId) {
      openTask(taskId);
    }
  };
  useEffect(() => {
    if (value instanceof Date && isNaN(value.getTime())) {
      setValueString("Invalid Date");
    } else if (value instanceof Date) {
      setValueString(value.toLocaleDateString());
    } else if (typeof value === "string") {
      setValueString(value);
    }
  }, [value]);
  return (
    <>
      {taskId ? (
        <Box
          onClick={openDrawer}
          sx={{ fontSize: 14, fontWeight: 500, color: palette.primary.main }}
        >
          {valueString}
        </Box>
      ) : href ? (
        <Link
          target={"_blank"}
          href={href}
          rel="noreferrer"
          sx={{ fontSize: 14, fontWeight: 500 }}
        >
          {valueString}
        </Link>
      ) : (
        <span>{valueString}</span>
      )}
    </>
  );
});
