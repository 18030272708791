import { deepCopy, yup } from "../../lib";
import {
  useCreateAnnouncementMutation,
  useDeleteAnnouncementMutation,
  useUpdateAnnouncementMutation,
} from "../../state/rtk-query/state/announcements";
import { ConfirmDeleteDialog } from "../tables";
import { BaseForm } from "./BaseForm";
import { GetAnnouncementsApiResponse } from "../../state/rtk-query/state/announcements";
import { useMemo } from "react";
import {
  DatePickerField,
  HtmlField,
  InputField,
  SelectField,
  isValidDate,
} from "./fields";
import { Grid } from "@mui/material";
import { useList } from "../hooks/useNewLists";

const validationSchema = yup.object({
  announcement: yup.string().required("Announcement is required"),
  dateRange: yup.array().test({
    message: "Start date and end date are required",
    test: (arr: any) => {
      return arr?.filter(isValidDate).length === 2;
    },
  }),
  button_link: yup
    .string()
    .transform((v) => (v.includes("http") ? v : "http://" + v))
    .url("Invalid URL"),
  button: yup.string().test({
    message: "Button is required with button link",
    test: (value, { parent }) => {
      return !parent.button_link || !!value;
    },
  }),
  orgs: yup
    .array(yup.number().required())
    .min(1, "Select at least 1 org or All")
    .required(),
});
interface Props {
  onClose: () => void;
  editAnnouncement?: GetAnnouncementsApiResponse["rows"][0];
}

export const AddAnnouncementForm = ({ onClose, editAnnouncement }: Props) => {
  const {
    id,
    announcement,
    end_date,
    start_date,
    is_global,
    type,
    button,
    button_link,
    orgs,
  } = editAnnouncement ?? {};
  const orgsList = useList("orgs");

  const orgsMenuItems = useMemo(() => {
    return [{ id: 0, name: "All" }, ...(orgsList.list || [])];
  }, [orgsList.list]);

  const initialValues = {
    announcement: announcement || "",
    dateRange: [
      start_date ? new Date(start_date) : null,
      end_date ? new Date(end_date) : null,
    ] as [Date | null, Date | null],
    type: type || "informational",
    type_hi: "",
    button: button || "",
    button_link: button_link || "",
    orgs: (is_global ? [0] : orgs?.map((o) => o.id)) || [],
  };

  const [submitAnnouncement] = useCreateAnnouncementMutation();
  const [updateAnnouncement] = useUpdateAnnouncementMutation();
  const [deleteAnnouncement] = useDeleteAnnouncementMutation();

  const handleSubmit = async ({
    announcement,
    button,
    button_link,
    dateRange,
    orgs,
    type,
  }: typeof initialValues) => {
    const is_global = orgs[0] === 0;
    const body = deepCopy({
      announcement,
      button,
      button_link,
      start_date: dateRange[0],
      end_date: dateRange[1],
      orgs: is_global ? [] : orgs,
      is_global,
      type,
    });
    if (editAnnouncement?.id) {
      updateAnnouncement({ body, id: editAnnouncement.id });
    } else {
      submitAnnouncement({ body });
    }
    return onClose();
  };

  return (
    <BaseForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onClose={onClose}
      enableReinitialize
      additionalButtons={
        id ? (
          <ConfirmDeleteDialog
            titleText="Delete"
            buttonText="Delete"
            buttonColor="primary"
            handleConfirm={() => {
              deleteAnnouncement({ id });
              onClose();
            }}
          />
        ) : undefined
      }
    >
      {({ setFieldValue, values: { orgs } }) => {
        const zeroIndex = orgs.indexOf(0);
        if (zeroIndex > -1) {
          if (orgs.length > 1) {
            if (zeroIndex === orgs.length - 1) {
              setFieldValue("orgs", [0]);
            } else {
              setFieldValue(
                "orgs",
                orgs.filter((o) => o !== 0),
              );
            }
          }
        }

        return (
          <Grid container rowSpacing={1.5} columnSpacing={2}>
            <SelectField
              items={orgsMenuItems}
              multiple
              name="orgs"
              topLabel="Applicable for"
            />
            <DatePickerField
              name="dateRange"
              disablePast={editAnnouncement ? false : true}
              topLabel="Date range"
            />
            <InputField name="button" topLabel="Button text" />
            <InputField name="button_link" topLabel="Button URL" />
            <SelectField
              topLabel="Announcement type"
              name="type"
              items={["informational", "alert"]}
              capitalize
              md12
            />
            <HtmlField name="announcement" topLabel md12 />
          </Grid>
        );
      }}
    </BaseForm>
  );
};
