import { api } from "../api";
export const addTagTypes = ["announcements"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      updateAnnouncement: build.mutation<
        UpdateAnnouncementApiResponse,
        UpdateAnnouncementApiArg
      >({
        query: (queryArg) => ({
          url: `/announcements/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["announcements"],
      }),
      deleteAnnouncement: build.mutation<
        DeleteAnnouncementApiResponse,
        DeleteAnnouncementApiArg
      >({
        query: (queryArg) => ({
          url: `/announcements/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["announcements"],
      }),
      getAnnouncements: build.query<
        GetAnnouncementsApiResponse,
        GetAnnouncementsApiArg
      >({
        query: (queryArg) => ({
          url: `/announcements`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
            archived: queryArg.archived,
          },
        }),
        providesTags: ["announcements"],
      }),
      createAnnouncement: build.mutation<
        CreateAnnouncementApiResponse,
        CreateAnnouncementApiArg
      >({
        query: (queryArg) => ({
          url: `/announcements`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["announcements"],
      }),
      dismissAnnouncement: build.mutation<
        DismissAnnouncementApiResponse,
        DismissAnnouncementApiArg
      >({
        query: (queryArg) => ({
          url: `/announcements/${queryArg.id}/dismiss`,
          method: "POST",
        }),
        invalidatesTags: ["announcements"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type UpdateAnnouncementApiResponse =
  /** status 204 An empty response */ void;
export type UpdateAnnouncementApiArg = {
  /** record id */
  id: number;
  /** announcement body */
  body: {
    id?: number | null;
    announcement: string;
    type: "informational" | "alert";
    start_date: string;
    end_date: string;
    button?: string | null;
    button_link?: string | null;
    created_by?: number;
    is_global?: boolean | null;
  } & {
    orgs: number[];
  };
};
export type DeleteAnnouncementApiResponse =
  /** status 204 An empty response */ void;
export type DeleteAnnouncementApiArg = {
  /** record id */
  id: number;
};
export type GetAnnouncementsApiResponse =
  /** status 200 announcement response */ {
    count: number;
    rows: ({
      id?: number | null;
      announcement: string;
      type: "informational" | "alert";
      start_date: string;
      end_date: string;
      button?: string | null;
      button_link?: string | null;
      created_by?: number;
      is_global?: boolean | null;
    } & {
      orgs?:
        | {
            id: number;
            name: string;
          }[]
        | null;
      created_by_user: {
        id: number;
        last_name: string;
        first_name: string;
      };
    })[];
  };
export type GetAnnouncementsApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
  /** get all archived */
  archived?: boolean;
};
export type CreateAnnouncementApiResponse =
  /** status 204 An empty response */ void;
export type CreateAnnouncementApiArg = {
  /** announcement body */
  body: {
    id?: number | null;
    announcement: string;
    type: "informational" | "alert";
    start_date: string;
    end_date: string;
    button?: string | null;
    button_link?: string | null;
    created_by?: number;
    is_global?: boolean | null;
  } & {
    orgs: number[];
  };
};
export type DismissAnnouncementApiResponse =
  /** status 204 An empty response */ void;
export type DismissAnnouncementApiArg = {
  /** record id */
  id: number;
};
export const {
  useUpdateAnnouncementMutation,
  useDeleteAnnouncementMutation,
  useGetAnnouncementsQuery,
  useLazyGetAnnouncementsQuery,
  useCreateAnnouncementMutation,
  useDismissAnnouncementMutation,
} = injectedRtkApi;
