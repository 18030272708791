import { api } from "../api";
export const addTagTypes = ["timer"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getMyTimers: build.query<GetMyTimersApiResponse, GetMyTimersApiArg>({
        query: (queryArg) => ({
          url: `/timers`,
          params: { date: queryArg.date, tz: queryArg.tz },
        }),
        providesTags: ["timer"],
      }),
      createTimer: build.mutation<CreateTimerApiResponse, CreateTimerApiArg>({
        query: (queryArg) => ({
          url: `/timers`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["timer"],
      }),
      bulkDeleteTimers: build.mutation<
        BulkDeleteTimersApiResponse,
        BulkDeleteTimersApiArg
      >({
        query: (queryArg) => ({
          url: `/timers`,
          method: "DELETE",
          body: queryArg.body,
        }),
        invalidatesTags: ["timer"],
      }),
      cancelTimers: build.mutation<CancelTimersApiResponse, CancelTimersApiArg>(
        {
          query: () => ({ url: `/timers/cancel`, method: "POST" }),
          invalidatesTags: ["timer"],
        },
      ),
      createTimerManual: build.mutation<
        CreateTimerManualApiResponse,
        CreateTimerManualApiArg
      >({
        query: (queryArg) => ({
          url: `/timers/manual`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["timer"],
      }),
      getMyReviewTimers: build.query<
        GetMyReviewTimersApiResponse,
        GetMyReviewTimersApiArg
      >({
        query: (queryArg) => ({
          url: `/timers/review`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
            pending: queryArg.pending,
          },
        }),
        providesTags: ["timer"],
      }),
      updateMyReviewedTimers: build.mutation<
        UpdateMyReviewedTimersApiResponse,
        UpdateMyReviewedTimersApiArg
      >({
        query: (queryArg) => ({
          url: `/timers/review`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["timer"],
      }),
      getMyActiveTimer: build.query<
        GetMyActiveTimerApiResponse,
        GetMyActiveTimerApiArg
      >({
        query: () => ({ url: `/timer/active` }),
        providesTags: ["timer"],
      }),
      stopActiveTimer: build.mutation<
        StopActiveTimerApiResponse,
        StopActiveTimerApiArg
      >({
        query: () => ({ url: `/timer/active`, method: "PUT" }),
        invalidatesTags: ["timer"],
      }),
      getTimerActivityTypes: build.query<
        GetTimerActivityTypesApiResponse,
        GetTimerActivityTypesApiArg
      >({
        query: () => ({ url: `/timer/activity-types` }),
        providesTags: ["timer"],
      }),
      getMyDailySubtotals: build.query<
        GetMyDailySubtotalsApiResponse,
        GetMyDailySubtotalsApiArg
      >({
        query: (queryArg) => ({
          url: `/timer/subtotals`,
          params: { date: queryArg.date, tz: queryArg.tz },
        }),
        providesTags: ["timer"],
      }),
      getTimerActions: build.query<
        GetTimerActionsApiResponse,
        GetTimerActionsApiArg
      >({
        query: () => ({ url: `/timer/actions` }),
        providesTags: ["timer"],
      }),
      createUserTimerActivity: build.mutation<
        CreateUserTimerActivityApiResponse,
        CreateUserTimerActivityApiArg
      >({
        query: (queryArg) => ({
          url: `/timer/actions`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["timer"],
      }),
      updateOpenTabs: build.mutation<
        UpdateOpenTabsApiResponse,
        UpdateOpenTabsApiArg
      >({
        query: (queryArg) => ({
          url: `/timer/open-tabs/${queryArg.orgId}`,
          method: "POST",
          params: { tab_count_direction: queryArg.tabCountDirection },
        }),
        invalidatesTags: ["timer"],
      }),
      getOpenTabCount: build.query<
        GetOpenTabCountApiResponse,
        GetOpenTabCountApiArg
      >({
        query: () => ({ url: `/timer/open-tabs` }),
        providesTags: ["timer"],
      }),
      getTimer: build.query<GetTimerApiResponse, GetTimerApiArg>({
        query: (queryArg) => ({ url: `/timer/${queryArg.id}` }),
        providesTags: ["timer"],
      }),
      updateTimer: build.mutation<UpdateTimerApiResponse, UpdateTimerApiArg>({
        query: (queryArg) => ({
          url: `/timer/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["timer"],
      }),
      deleteTimer: build.mutation<DeleteTimerApiResponse, DeleteTimerApiArg>({
        query: (queryArg) => ({
          url: `/timer/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["timer"],
      }),
      updateTimerManual: build.mutation<
        UpdateTimerManualApiResponse,
        UpdateTimerManualApiArg
      >({
        query: (queryArg) => ({
          url: `/timer/${queryArg.id}/manual`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["timer"],
      }),
      getTimeSpentOnAllOrgs: build.query<
        GetTimeSpentOnAllOrgsApiResponse,
        GetTimeSpentOnAllOrgsApiArg
      >({
        query: (queryArg) => ({
          url: `/timer/orgs/time-spent`,
          params: {
            dateRange: queryArg.dateRange,
            filter_time: queryArg.filterTime,
          },
        }),
        providesTags: ["timer"],
      }),
      getTimeSpentOnAllOrgsSummary: build.query<
        GetTimeSpentOnAllOrgsSummaryApiResponse,
        GetTimeSpentOnAllOrgsSummaryApiArg
      >({
        query: (queryArg) => ({
          url: `/timer/orgs/time-spent/summary`,
          params: { dateRange: queryArg.dateRange },
        }),
        providesTags: ["timer"],
      }),
      getDetailedTimeSpentOnAllOrgs: build.query<
        GetDetailedTimeSpentOnAllOrgsApiResponse,
        GetDetailedTimeSpentOnAllOrgsApiArg
      >({
        query: (queryArg) => ({
          url: `/timer/orgs/time-spent/detailed`,
          params: {
            dateRange: queryArg.dateRange,
            filter_time: queryArg.filterTime,
            tz: queryArg.tz,
          },
        }),
        providesTags: ["timer"],
      }),
      getMyTimeSpentOnAllOrgs: build.query<
        GetMyTimeSpentOnAllOrgsApiResponse,
        GetMyTimeSpentOnAllOrgsApiArg
      >({
        query: (queryArg) => ({
          url: `/timer/orgs/time-spent/detailed/mine`,
          params: { dateRange: queryArg.dateRange, tz: queryArg.tz },
        }),
        providesTags: ["timer"],
      }),
      getInternalTimeSpentOnOrg: build.query<
        GetInternalTimeSpentOnOrgApiResponse,
        GetInternalTimeSpentOnOrgApiArg
      >({
        query: (queryArg) => ({
          url: `/timer/${queryArg.orgId}/internal-time-spent`,
          params: { dateRange: queryArg.dateRange },
        }),
        providesTags: ["timer"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetMyTimersApiResponse = /** status 200 Timers */ {
  count: number;
  rows: ({
    id: number;
    org_id?: number;
    user_id?: number;
    start_time?: string | null;
    end_time?: string | null;
    duration?: number | null;
    activity_type_id?: number | null;
    notes?: string | null;
    is_manual?: boolean | null;
    duration_date?: string | null;
    task_id?: number | null;
    duration_total?: number | null;
    minutes_applied_towards_included?: number;
    status?: "new" | "reviewed" | "billing" | "billed" | "old";
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
    task_title?: string | null;
    first_timer_of_group?: number | null;
    activity_type?: {
      id?: number | null;
      name?: string | null;
      internal_name?: string | null;
    } | null;
    org?: {
      id?: number;
      name?: string;
    };
    user_activities?:
      | {
          id?: number;
          timer_id?: number | null;
          action_id?: number | null;
          entity?: "org" | "session" | "task";
          entity_id?: number;
          created_at?: string;
          updated_at?: string | null;
          deleted_at?: string | null;
          title?: string | null;
          action?: {
            id?: number | null;
            name?: string | null;
            internal_name?: string | null;
          } | null;
        }[]
      | null;
  } | null)[];
};
export type GetMyTimersApiArg = {
  /** singular date to filter by */
  date: string;
  /** user's timezone */
  tz?: string;
};
export type CreateTimerApiResponse = /** status 200 id */ {
  id: number;
};
export type CreateTimerApiArg = {
  /** timer body */
  body: {
    is_manual?: boolean | null;
    billed?: boolean | null;
    task_id?: number | null;
    start_time?: string | null;
    end_time?: string | null;
    activity_type_id?: number | null;
    entity_id?: number | null;
    entity?: ("org" | "task" | "session") | null;
    org_id: number;
    duration?: number | null;
    duration_date?: string | null;
    action_id?: number | null;
    notes?: string | null;
  };
};
export type BulkDeleteTimersApiResponse =
  /** status 204 An empty response */ void;
export type BulkDeleteTimersApiArg = {
  /** mark timers reviewed */
  body: {
    timers: number[];
  };
};
export type CancelTimersApiResponse = /** status 204 An empty response */ void;
export type CancelTimersApiArg = void;
export type CreateTimerManualApiResponse =
  /** status 204 An empty response */ void;
export type CreateTimerManualApiArg = {
  /** timer body */
  body: {
    billed?: boolean | null;
    task_id?: number | null;
    start_time?: string | null;
    end_time?: string | null;
    activity_type_id?: number | null;
    org_id: number;
    duration?: number | null;
    date?: string;
    notes?: string | null;
  };
};
export type GetMyReviewTimersApiResponse =
  /** status 200 Timers for review page */ {
    count: number;
    rows: {
      id: number;
      org_id?: number;
      user_id?: number;
      start_time?: string | null;
      end_time?: string | null;
      duration?: number | null;
      activity_type_id?: number | null;
      notes?: string | null;
      is_manual?: boolean | null;
      duration_date?: string | null;
      task_id?: number | null;
      duration_total?: number | null;
      minutes_applied_towards_included?: number;
      status?: "new" | "reviewed" | "billing" | "billed" | "old";
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
      first_timer_of_group?: number | null;
      activity_type?: {
        id?: number | null;
        name?: string | null;
        internal_name?: string | null;
      } | null;
      org?: {
        id?: number;
        name?: string;
      };
    }[];
  };
export type GetMyReviewTimersApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
  /** get all pending */
  pending?: boolean;
};
export type UpdateMyReviewedTimersApiResponse =
  /** status 204 An empty response */ void;
export type UpdateMyReviewedTimersApiArg = {
  /** mark timers reviewed */
  body: {
    timers: number[];
    reviewed?: boolean | null;
  };
};
export type GetMyActiveTimerApiResponse = /** status 200 Show single timer */ {
  id: number;
  org_id?: number;
  user_id?: number;
  start_time?: string | null;
  end_time?: string | null;
  duration?: number | null;
  activity_type_id?: number | null;
  notes?: string | null;
  is_manual?: boolean | null;
  duration_date?: string | null;
  task_id?: number | null;
  duration_total?: number | null;
  minutes_applied_towards_included?: number;
  status?: "new" | "reviewed" | "billing" | "billed" | "old";
  created_at?: string;
  updated_at?: string | null;
  deleted_at?: string | null;
  task_title?: string | null;
  first_timer_of_group?: number | null;
  activity_type?: {
    id?: number | null;
    name?: string | null;
    internal_name?: string | null;
  } | null;
  org?: {
    id?: number;
    name?: string;
  };
  user_activities?:
    | {
        id?: number;
        timer_id?: number | null;
        action_id?: number | null;
        entity?: "org" | "session" | "task";
        entity_id?: number;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
        title?: string | null;
        action?: {
          id?: number | null;
          name?: string | null;
          internal_name?: string | null;
        } | null;
      }[]
    | null;
} | null;
export type GetMyActiveTimerApiArg = void;
export type StopActiveTimerApiResponse =
  /** status 204 An empty response */ void;
export type StopActiveTimerApiArg = void;
export type GetTimerActivityTypesApiResponse = /** status 200 Timer types */ {
  count: number;
  rows: {
    id: number;
    internal_name: string;
    name: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetTimerActivityTypesApiArg = void;
export type GetMyDailySubtotalsApiResponse =
  /** status 200 Timers subtotals */ {
    subtotal?: number | null;
    date?: string | null;
  }[];
export type GetMyDailySubtotalsApiArg = {
  /** singular date to filter by */
  date: string;
  /** user's timezone */
  tz?: string;
};
export type GetTimerActionsApiResponse = /** status 200 Timer actions */ {
  count: number;
  rows: {
    id: number;
    name: string;
    internal_name: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetTimerActionsApiArg = void;
export type CreateUserTimerActivityApiResponse =
  /** status 204 An empty response */ void;
export type CreateUserTimerActivityApiArg = {
  /** user activity body */
  body: {
    timer_id?: number | null;
    action_id: number;
    entity_id: number;
    entity: "org" | "task" | "session";
  };
};
export type UpdateOpenTabsApiResponse =
  /** status 204 An empty response */ void;
export type UpdateOpenTabsApiArg = {
  /** whether a tab should be incremented or decremented */
  tabCountDirection: "increment" | "decrement";
  /** organization id */
  orgId: number;
};
export type GetOpenTabCountApiResponse = /** status 200 Open tabs for timer */ {
  count: number;
  rows: {
    id: number;
    org_id: number;
    user_id: number;
    tab_count: number;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetOpenTabCountApiArg = void;
export type GetTimerApiResponse = /** status 200 Show single timer */ {
  id: number;
  org_id?: number;
  user_id?: number;
  start_time?: string | null;
  end_time?: string | null;
  duration?: number | null;
  activity_type_id?: number | null;
  notes?: string | null;
  is_manual?: boolean | null;
  duration_date?: string | null;
  task_id?: number | null;
  duration_total?: number | null;
  minutes_applied_towards_included?: number;
  status?: "new" | "reviewed" | "billing" | "billed" | "old";
  created_at?: string;
  updated_at?: string | null;
  deleted_at?: string | null;
  task_title?: string | null;
  first_timer_of_group?: number | null;
  activity_type?: {
    id?: number | null;
    name?: string | null;
    internal_name?: string | null;
  } | null;
  org?: {
    id?: number;
    name?: string;
  };
  user_activities?:
    | {
        id?: number;
        timer_id?: number | null;
        action_id?: number | null;
        entity?: "org" | "session" | "task";
        entity_id?: number;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
        title?: string | null;
        action?: {
          id?: number | null;
          name?: string | null;
          internal_name?: string | null;
        } | null;
      }[]
    | null;
} | null;
export type GetTimerApiArg = {
  /** record id */
  id: number;
};
export type UpdateTimerApiResponse = /** status 204 An empty response */ void;
export type UpdateTimerApiArg = {
  /** record id */
  id: number;
  /** timer body */
  body: {
    is_manual?: boolean | null;
    billed?: boolean | null;
    task_id?: number | null;
    start_time?: string | null;
    end_time?: string | null;
    activity_type_id?: number | null;
    entity_id?: number | null;
    entity?: ("org" | "task" | "session") | null;
    org_id: number;
    duration?: number | null;
    duration_date?: string | null;
    action_id?: number | null;
    notes?: string | null;
  };
};
export type DeleteTimerApiResponse = /** status 204 An empty response */ void;
export type DeleteTimerApiArg = {
  /** record id */
  id: number;
};
export type UpdateTimerManualApiResponse =
  /** status 204 An empty response */ void;
export type UpdateTimerManualApiArg = {
  /** record id */
  id: number;
  /** timer body */
  body: {
    billed?: boolean | null;
    task_id?: number | null;
    start_time?: string | null;
    end_time?: string | null;
    activity_type_id?: number | null;
    org_id: number;
    duration?: number | null;
    date?: string;
    notes?: string | null;
  };
};
export type GetTimeSpentOnAllOrgsApiResponse = /** status 200 Usage by user */ {
  org_id: number;
  label: string;
  sessions: number;
  tasks: number;
  total: number;
  suggested_avg: number;
  avg?: number;
  avg_range?: number[];
}[];
export type GetTimeSpentOnAllOrgsApiArg = {
  /** date range to filter by */
  dateRange?: string[];
  /** filter time for admin */
  filterTime?:
    | "all_orgs"
    | "below_avg"
    | "avg"
    | "above_avg"
    | "no_sessions"
    | "no_tasks"
    | "no_support";
};
export type GetTimeSpentOnAllOrgsSummaryApiResponse =
  /** status 200 Counts to display for filter types on admin time spent page */ {
    count: number;
    label: string;
  }[];
export type GetTimeSpentOnAllOrgsSummaryApiArg = {
  /** date range to filter by */
  dateRange?: string[];
};
export type GetDetailedTimeSpentOnAllOrgsApiResponse =
  /** status 200 detailed report for all orgs */ {
    id: number;
    user_id: number;
    consultant: string;
    org_id: number;
    org_name: string;
    task_id?: number | null;
    task_title?: string | null;
    task_code?: string | null;
    timer_type?: string | null;
    time_spent: number;
    start_time?: string | null;
    end_time?: string | null;
    duration_date?: string | null;
    activities?:
      | (
          | ({
              task_id?: number | null;
              title?: string | null;
              code?: string | null;
            } | null)[]
          | null
        )[]
      | null;
  }[];
export type GetDetailedTimeSpentOnAllOrgsApiArg = {
  /** date range to filter by */
  dateRange?: string[];
  /** filter time for admin */
  filterTime?:
    | "all_orgs"
    | "below_avg"
    | "avg"
    | "above_avg"
    | "no_sessions"
    | "no_tasks"
    | "no_support";
  /** user's timezone */
  tz?: string;
};
export type GetMyTimeSpentOnAllOrgsApiResponse =
  /** status 200 detailed report for all orgs */ {
    id: number;
    user_id: number;
    consultant: string;
    org_id: number;
    org_name: string;
    task_id?: number | null;
    task_title?: string | null;
    task_code?: string | null;
    timer_type?: string | null;
    time_spent: number;
    start_time?: string | null;
    end_time?: string | null;
    duration_date?: string | null;
    activities?:
      | (
          | ({
              task_id?: number | null;
              title?: string | null;
              code?: string | null;
            } | null)[]
          | null
        )[]
      | null;
  }[];
export type GetMyTimeSpentOnAllOrgsApiArg = {
  /** date range to filter by */
  dateRange?: string[];
  /** user's timezone */
  tz?: string;
};
export type GetInternalTimeSpentOnOrgApiResponse =
  /** status 200 Usage by user */ {
    count: number;
    rows: {
      user?: {
        id: number;
        first_name: string;
        last_name: string;
      };
      sessions?: number;
      tasks?: number;
    }[];
  };
export type GetInternalTimeSpentOnOrgApiArg = {
  /** date range to filter by */
  dateRange?: string[];
  /** organization id */
  orgId: number;
};
export const {
  useGetMyTimersQuery,
  useLazyGetMyTimersQuery,
  useCreateTimerMutation,
  useBulkDeleteTimersMutation,
  useCancelTimersMutation,
  useCreateTimerManualMutation,
  useGetMyReviewTimersQuery,
  useLazyGetMyReviewTimersQuery,
  useUpdateMyReviewedTimersMutation,
  useGetMyActiveTimerQuery,
  useLazyGetMyActiveTimerQuery,
  useStopActiveTimerMutation,
  useGetTimerActivityTypesQuery,
  useLazyGetTimerActivityTypesQuery,
  useGetMyDailySubtotalsQuery,
  useLazyGetMyDailySubtotalsQuery,
  useGetTimerActionsQuery,
  useLazyGetTimerActionsQuery,
  useCreateUserTimerActivityMutation,
  useUpdateOpenTabsMutation,
  useGetOpenTabCountQuery,
  useLazyGetOpenTabCountQuery,
  useGetTimerQuery,
  useLazyGetTimerQuery,
  useUpdateTimerMutation,
  useDeleteTimerMutation,
  useUpdateTimerManualMutation,
  useGetTimeSpentOnAllOrgsQuery,
  useLazyGetTimeSpentOnAllOrgsQuery,
  useGetTimeSpentOnAllOrgsSummaryQuery,
  useLazyGetTimeSpentOnAllOrgsSummaryQuery,
  useGetDetailedTimeSpentOnAllOrgsQuery,
  useLazyGetDetailedTimeSpentOnAllOrgsQuery,
  useGetMyTimeSpentOnAllOrgsQuery,
  useLazyGetMyTimeSpentOnAllOrgsQuery,
  useGetInternalTimeSpentOnOrgQuery,
  useLazyGetInternalTimeSpentOnOrgQuery,
} = injectedRtkApi;
