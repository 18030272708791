import { IconButton, Button, SxProps, Theme } from "@mui/material";
import { memo } from "react";
import { useMobile } from "../../themes";
import { useMemoObj } from "../hooks";
import { StopIcon } from "../icons";
import { PropsOf } from "@emotion/react";

interface TimerButtonProps {
  onClick: () => void;
  Icon?: typeof StopIcon;
  text: string;
  color?: PropsOf<typeof Button>["color"];
  sx?: SxProps<Theme>;
  disabled?: boolean;
}
export const TimerButton = memo(
  ({ onClick, Icon, text, color, sx, disabled = false }: TimerButtonProps) => {
    const isMobile = useMobile();

    const styles = useMemoObj({
      backgroundColor: "transparent !important",
      color: color ?? "#FFF !important",
      width: "100%",
      whiteSpace: "nowrap",
      minWidth: "fit-content",
      ...sx,
      "&.MuiButtonBase-root.MuiButton-root:disabled": {
        backgroundColor: "rgba(0, 0, 0, 0) !important",
        color: "rgb(255, 255, 255) !important",
        cursor: "not-allowed",
        "&.MuiButton-root:hover": {
          cursor: "not-allowed",
        },
      },
    });
    const iconProps = useMemoObj({
      size: isMobile ? 16 : 18,
      style: { marginRight: isMobile ? 0 : "4px" },
    });

    return (
      <>
        {isMobile && Icon ? (
          <IconButton
            disabled={disabled}
            onClick={onClick}
            color={color ? "primary" : "white"}
            sx={{ ...styles }}
          >
            <Icon {...iconProps} />
          </IconButton>
        ) : (
          <Button
            disabled={disabled}
            variant="outlined"
            color={color || "white"}
            sx={{
              ...styles,
              fontSize: 16,
              fontWeight: 600,
              p: 1,
              height: "32px",
            }}
            onClick={onClick}
          >
            {Icon ? <Icon {...iconProps} /> : null}
            {text}
          </Button>
        )}
      </>
    );
  },
);
