import {
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ChevronDown } from "../icons";
import { useMemo, useState } from "react";

interface ToggleDropdownProps {
  options: string[] | { label: string; value: string }[];
  value: string;
  setValue: (val: string) => void;
  buttonPadding?: number;
}

export function ToggleDropdown({
  options,
  value,
  setValue,
  buttonPadding,
}: ToggleDropdownProps) {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const anchorOrigin = { vertical: "bottom", horizontal: "left" } as const;
  const transformOrigin = {
    vertical: "top",
    horizontal: "left",
  } as const;

  const curLabel = useMemo(() => {
    const find = options.find((option) =>
      typeof option === "string" ? option === value : option.value === value,
    );
    return typeof find === "string" ? find : find?.label || "";
  }, [options, value]);
  return (
    <Stack width={"auto"}>
      <Button
        onClick={handleClick}
        color="black"
        aria-expanded={open ? "true" : undefined}
        sx={{
          width: "fit-content",
          height: "max-content",
          ...(buttonPadding !== undefined && { p: buttonPadding }),
        }}
      >
        <Typography
          fontSize={"1em"}
          width={"100%"}
          fontWeight={500}
          color="primary"
          display={"flex"}
          alignItems={"center"}
          noWrap
        >
          <Box component={"span"}>
            {curLabel
              .split("_")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ")}
          </Box>
          <ChevronDown color={palette.primary.main} />
        </Typography>
      </Button>
      <Menu
        aria-labelledby="sortable-button-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        sx={{ maxHeight: "100%" }}
      >
        {options.map((option) => {
          const isString = typeof option === "string";
          const label = isString ? option : option.label;
          const selectedValue = isString ? option : option.value;
          return (
            <MenuItem
              key={label}
              value={selectedValue}
              sx={{
                ":hover": { bgcolor: palette.accent[50] },
                bgcolor:
                  selectedValue === option ? palette.accent[50] : undefined,
              }}
              onClick={() => {
                setValue(selectedValue);
                handleClose();
              }}
            >
              {label
                .split("_")
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(" ")}
            </MenuItem>
          );
        })}
      </Menu>
    </Stack>
  );
}
