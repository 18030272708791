import { useEffect, useMemo, useState } from "react";
import {
  QueryParams,
  datagridColType,
  formatDate,
  formatPhone,
  generatePath,
} from "../../lib";
import {
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { Box, MenuItem, Typography } from "@mui/material";
import {
  GetReportByIdApiResponse,
  useGetReportByIdQuery,
  useRunReportActionMutation,
} from "../../state/rtk-query/state/reports";
import { skipToken } from "@reduxjs/toolkit/query";

import { JSONPopup } from "../misc";

import { MainPages } from "../../pages";
import { useSelector, authSelectors } from "../../state";
import { useRtkQueryParams } from ".";
import { stripHtml } from "string-strip-html";
import { DropDownButton } from "../buttons/DropDownButton";
import { MoreVertIcon, Tooltip } from "../icons";
import { ReportLink } from "../misc/ReportLink";

function ReportStringCol({
  value,
  field,
  type,
}: {
  value: string;
  field: string;
  type: string;
}) {
  const val =
    field === "phone"
      ? formatPhone(value)
      : type === "number"
      ? Number(value).toFixed(0)
      : stripHtml(`${value || ""}`)?.result;
  const valComp = (
    <Box
      component={"span"}
      sx={{
        fontWeight: 500,
        fontSize: 14,
        overflow: "hidden",
      }}
    >
      {val}
    </Box>
  );
  if (val && val.length > 100) {
    return (
      <Tooltip
        title={
          <>
            {val.includes("\n\n")
              ? val.split("\n\n").map((v, i) => (
                  <Typography key={i}>
                    {i + 1}. {v}
                  </Typography>
                ))
              : val.split("\n").map((v, i) => (
                  <Typography key={i}>
                    {i + 1}. {v}
                  </Typography>
                ))}
          </>
        }
      >
        {valComp}
      </Tooltip>
    );
  }
  return valComp;
}

interface Props {
  id: number;
  paramsSearch: QueryParams & { id: number };
  data?: object[] | undefined;
  count?: number;
  actions?: GetReportByIdApiResponse["actions"];
}
export const useReportDynamicTable = ({
  id,
  paramsSearch,
  data: pageData,
  count: pageCount,
  actions,
}: Props) => {
  const isInternal = useSelector(authSelectors.isInternal);
  const isAdmin = useSelector(authSelectors.isInternal);
  const pages = MainPages(isInternal, isAdmin);
  const [reportData, setReportData] = useState<any>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [params, setParams] = useRtkQueryParams({
    id,
  });

  const shouldSearch = id && paramsSearch && paramsSearch?.id && !pageData;
  const {
    currentData: report,
    isFetching,
    fulfilledTimeStamp,
  } = useGetReportByIdQuery(shouldSearch ? paramsSearch : skipToken, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const count = useMemo(() => pageCount ?? report?.count, [pageCount, report]);
  const data = useMemo(() => pageData ?? report?.data, [pageCount, report]);
  const [runReportAction, { isLoading }] = useRunReportActionMutation();
  useEffect(() => {
    if (!id) {
      return;
    }
    if (!data?.length) {
      setReportData([]);
    } else {
      const columns = Object.keys(data[0])
        .map((c) => {
          // otherwise there
          const d: any = data;
          const type = datagridColType(d[0][c]);
          const header = c.replace("_", " ");
          // don't render these columns
          return ["org_id", "id", "session_id", "task_id"].includes(c)
            ? null
            : {
                field: c,
                headerName: header,
                minWidth: 100,
                width: "100%",
                align: "left",
                headerAlign: "left",
                flex: 1,
                type: type !== "object" && type,
                sortable: type !== "object",
                filterable: type !== "object",
                valueGetter: ({
                  value,
                }: GridValueGetterParams<any, any, GridTreeNodeWithRender>) => {
                  const getDateYear = new Date(value).getFullYear();
                  return type === "dateTime"
                    ? getDateYear < 1985
                      ? ""
                      : new Date(value)
                    : value;
                },
                renderCell: (props: GridRenderCellParams) => {
                  const renderOrgLink =
                    props["field"] === "organization" && props.row.org_id;
                  const renderSessionLink =
                    props["field"] === "session" && props.row.session;
                  const renderTaskLink =
                    props["field"] === "task" && props.row.task;

                  return !("value" in props) ? null : type === "object" ? (
                    <JSONPopup {...props} />
                  ) : renderOrgLink ? (
                    <ReportLink
                      href={`${pages.organizations.path}/${props.row.org_id}`}
                      value={props.value}
                    />
                  ) : renderSessionLink ? (
                    <ReportLink
                      href={generatePath(pages.session.path, {
                        id: props.row.session_id,
                      })}
                      value={props.value}
                    />
                  ) : renderTaskLink ? (
                    <ReportLink
                      taskId={props.row.task_id}
                      value={props.value}
                    />
                  ) : type === "dateTime" ? (
                    formatDate(props.value)
                  ) : (
                    <ReportStringCol
                      value={props.value}
                      field={props["field"]}
                      type={type}
                    />
                  );
                },
              };
        })
        .filter(Boolean) as unknown[] as GridColDef[];
      if (actions?.length) {
        columns.push({
          field: "actions",
          type: "actions",
          width: 20,
          headerName: "",
          renderCell(props) {
            return (
              <DropDownButton
                items={actions}
                menuToLeft
                renderItems={(
                  { id, report_arg_key, action_label, report_id },
                  index,
                  close,
                ) => {
                  const arg =
                    report_arg_key && report_arg_key in props
                      ? JSON.stringify(props.row[report_arg_key])
                      : null;
                  return (
                    <MenuItem
                      disabled={
                        isLoading ||
                        !!(report_arg_key && !(report_arg_key in props.row))
                      }
                      onClick={() => {
                        runReportAction({
                          id: report_id,
                          reportActionId: id,
                          body: arg ? { arg } : {},
                        });
                        close();
                      }}
                    >
                      {action_label}
                    </MenuItem>
                  );
                }}
              >
                <MoreVertIcon fontSize="small" />
              </DropDownButton>
            );
          },
        });
      }
      setColumns(columns);
      // check all id's are unique
      const set = new Set(
        data.map((d) => ("id" in d ? Number(d?.id) : 0)).filter(Boolean),
      );

      if (set.size === data.length) setReportData(data);
      else {
        setReportData([]);
        // setNoRowsReason("It's possible that there are duplicate id's ");
      }
    }
  }, [id, count, isFetching]);
  return {
    reportData,
    columns,
    isFetching,
    count,
    params,
    setParams,
    fulfilledTimeStamp,
  };
};
