import { Stack, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { timerSelectors, authSelectors } from "../../state";
import { useMobile } from "../../themes/defaultTheme";
import { TimerButton } from "../buttons";
import { useMemoObj, useOrgsList, useStopTimer } from "../hooks";
import { WarningIcon } from "../icons";

interface SwitchingMsgProp {
  switchingCount: number;
  stayOnCurrentOrg: () => void;
  switchToNextOrg: () => void;
}

export function SwitchingMessage({
  switchingCount,
  stayOnCurrentOrg,
  switchToNextOrg,
}: SwitchingMsgProp) {
  const isMobile = useMobile();
  const iconProps = useMemoObj({
    size: 18,
    style: { marginRight: "4px" },
  });
  const inferredOrgId = useSelector(timerSelectors.inferredOrgId);
  const isInternal = useSelector(authSelectors.isInternal);
  const { getOrg } = useOrgsList(!isInternal);
  const inferredOrg = getOrg(Number(inferredOrgId));
  const timerState = useSelector(timerSelectors.timerState);
  const stopTimer = useStopTimer();
  return (
    <Stack
      direction={"row"}
      alignItems={isMobile ? "flex-start" : "center"}
      mr={isMobile ? 0 : 4}
      justifyContent={"space-between"}
      width={"max-content"}
      gap={1.5}
    >
      <WarningIcon {...iconProps} style={{ minWidth: "fit-content" }} />{" "}
      <Typography
        fontSize={16}
        fontWeight={600}
        ml={1}
        mr={2}
        whiteSpace={"nowrap"}
      >
        {timerState === "switching" && (
          <span style={{ marginRight: 8 }}>
            Switching to {inferredOrg?.name} in
          </span>
        )}
        {timerState === "stopping" && (
          <span style={{ marginRight: 8 }}>Stopping in</span>
        )}
        <Box
          component={"span"}
          sx={{
            display: "inline-block",
            width: "2ch", // 2 characters long (to prevent shifting of "stay" button)
          }}
        >
          {switchingCount}
        </Box>
      </Typography>
      <TimerButton onClick={stayOnCurrentOrg} text="Stay" />
      {timerState === "switching" && (
        <TimerButton onClick={switchToNextOrg} text="Switch" />
      )}
      {timerState === "stopping" && (
        <TimerButton onClick={stopTimer} text="Stop" />
      )}
    </Stack>
  );
}
