import { Typography, Stack } from "@mui/material";
import { UsersTable } from "../../../components/tables/UsersTable";
import { CardStyled, PageTabs } from "../../../components";
import { useMobile, useTablet } from "../../../themes";
import { useQueryParam } from "../../../components/hooks";
import { ToggleDropdown } from "../../../components/buttons/ToggleDropdown";

export const UsersPage = () => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isSmallerThanPc = isMobile || isTablet;
  const [inactive, setInactive] = useQueryParam<boolean | undefined>(
    "inactive",
  );
  return (
    <UsersTable mode="admin">
      {({ AddUserButton, AddUserModel, UsersTable }) => (
        <>
          <Stack
            direction={"row"}
            width="100%"
            justifyContent="space-between"
            my={isSmallerThanPc ? 2 : 0}
            mb={1}
          >
            <Stack direction={"row"} gap={2} alignItems={"center"}>
              <Typography variant="h2">All users</Typography>
              <ToggleDropdown
                options={["active", "inactive"]}
                setValue={(val) => setInactive(val === "inactive" || undefined)}
                value={inactive ? "inactive" : "active"}
              />
            </Stack>
            {AddUserButton}
          </Stack>
          <PageTabs
            tabValues={[
              { id: "admire", label: "Admire" },
              { id: "client", label: "Client" },
            ]}
            defaultTab="client"
            tabKey="userType"
            maintainQuery
          />
          <CardStyled sx={{ height: "100%", mb: 2 }}>{UsersTable}</CardStyled>
          {AddUserModel}
        </>
      )}
    </UsersTable>
  );
};
