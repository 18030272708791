import {
  Typography,
  IconButton,
  Stack,
  Button,
  Divider,
  Box,
} from "@mui/material";
import { CloseIcon } from "../icons";
import { StyledFormBox } from "./SupportForms.styles";
import { useGetUpcomingSessionsQuery } from "../../state/rtk-query/state/session";
import { useDispatch, useSelector } from "react-redux";
import { clientActions, clientSelectors } from "../../state";
import { Navigation } from "../../lib";
import { ReactNode, useCallback, useEffect } from "react";
import { ClientState } from "../../state/client/state";
import { AddTaskForm } from "./AddTaskForm";
import { CreateSessionForm } from "./CreateSessionForm";
import { useMobile } from "../../themes";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { SelectSessionForm } from ".";
import { useOpenTaskDrawer, useOrgId } from "../hooks";
import { useMoveTaskToSessionMutation } from "../../state/rtk-query/state/tasks";
import { MainPages } from "../../pages";
import { matchPath } from "react-router-dom";
import { ApplyTemplateToOrg } from "./ApplyTemplateToOrgForm";

type SupportType = ClientState["support"];
interface ScreenSelection {
  title: string;
  description: string | ReactNode;
  to: SupportType["screen"];
}
export interface SupportFormProps {
  onClose: () => void;
  open?: boolean;
  defaultScreen?: SupportType["screen"];
  movingTaskId?: number;
  orgId?: number;
  continueMovingFlow?: boolean;
  isNotSessionTask?: boolean;
}
export const SupportForm = ({
  open,
  onClose,
  defaultScreen,
  movingTaskId,
  orgId: _orgId,
  isNotSessionTask,
  continueMovingFlow = false,
}: SupportFormProps) => {
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const orgIdHook = useOrgId();
  const orgId = _orgId || orgIdHook;
  const openTaskDrawer = useOpenTaskDrawer();
  const [moveToSession] = useMoveTaskToSessionMutation();
  const { screen, sessionId, taskId } = useSelector(
    clientSelectors.currentSupportStep,
  );
  const { currentData: upComingSessions } = useGetUpcomingSessionsQuery(
    orgId
      ? {
          orgId,
          includePending: true,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const upcomingCount = Number(upComingSessions?.rows.length ?? 0);
  const pendingCount = Number(
    upComingSessions?.rows.filter((r) => !r.booking).length ?? 0,
  );
  const SelectionScreens: ScreenSelection[] = [
    {
      title: "Schedule a new session",
      description: `No need to decide on a particular date or tasks at this time. You
              can schedule the session at a later date, and you can always add
              tasks.`,
      to: "CreateSession",
    },
    {
      title: "Add tasks to an upcoming session",
      description: (
        <>
          You have{" "}
          <Box component={"span"} fontWeight={700} fontSize={14} pl={0.5}>{` ${
            upcomingCount - pendingCount || 0
          } upcoming sessions
          ${
            pendingCount
              ? ` and ${pendingCount} pending session${
                  pendingCount > 1 ? "s" : ""
                }`
              : ""
          }. `}</Box>
          Use this option to add your new task to one of your upcoming sessions.
        </>
      ),
      to: "SessionSelection",
    },
    {
      title: "Ask a quick question",
      description: ` Use this option for quick questions that do not require a session,
              or those critical tasks that can't wait until your next session.`,
      to: "AddQuickQuestion",
    },
    {
      title: "Request custom work",
      description: `Please submit your request at least 2 weeks in advance. While
              we'll do our best to accommodate, we can't guarantee your
              requested delivery date.`,
      to: "AddCustomWork",
    },
  ];

  let modalTitle;
  switch (screen) {
    case "TypeSelection":
      modalTitle = "Get Support";
      break;
    case "SessionSelection":
      modalTitle = "Choose Session";
      break;
    case "AddTask":
      modalTitle = "Add Task";
      break;
    case "AddCustomWork":
      modalTitle = "Custom Work";
      break;
    case "AddQuickQuestion":
      modalTitle = "Quick Question";
      break;
    case "AddInternalTask":
      modalTitle = "Internal task";
      break;
    case "MoveTaskComplete":
      modalTitle = "Task moved successfully";
      break;
    case "CreateSession":
    case "CreatePendingSession":
    case "Done":
      modalTitle = "Create Session";
      break;
    case "ApplyTemplateToOrg":
      modalTitle = "Apply Template to Organization";
      break;
    case "RequestSubmitted":
    default:
      modalTitle = "";
  }

  useEffect(() => {
    dispatch(
      clientActions.setSupportScreenAction(defaultScreen ?? "TypeSelection"),
    );
  }, [open]);

  const changeScreenSelection = useCallback(
    async (newScreen: SupportType["screen"], sessionId?: number) => {
      if (newScreen === "AddTask" && sessionId) {
        Navigation.go(`/sessions/${sessionId}`);
        dispatch(clientActions.setSupportSessionId(sessionId));
        if (matchPath(MainPages(true).session.path, location.pathname)) {
          onClose();
        }
      } else if (newScreen === "MoveTask" && movingTaskId && sessionId) {
        await moveToSession({
          id: movingTaskId,
          orgId,
          sessionId,
        });
        return dispatch(
          clientActions.setSupportScreenAction("MoveTaskComplete"),
        );
      }
      dispatch(clientActions.setSupportScreenAction(newScreen));
    },
    [sessionId, orgId, movingTaskId, screen],
  );

  const createPendingClick = useCallback(
    (id: number) => changeScreenSelection("AddTask", id),
    [screen],
  );
  const goToSessionClick = useCallback(() => {
    if (sessionId) {
      Navigation.go(`/sessions/${sessionId}`);
      onClose();
    }
  }, [sessionId, onClose, screen]);

  const goToTaskAndClose = useCallback(() => {
    if (taskId) {
      openTaskDrawer(taskId);
    }
    onClose();
  }, [taskId, onClose, screen]);

  const isTaskScreen = [
    "AddTask",
    "AddQuickQuestion",
    "AddCustomWork",
    "AddInternalTask",
  ].includes(screen);

  return (
    <Stack direction={"column"} gap={2}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        px={
          screen === "CreateSession"
            ? isMobile
              ? 2
              : 7
            : isTaskScreen
            ? isMobile
              ? 0
              : 4
            : "initial"
        }
      >
        <Typography variant="h1">{modalTitle}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      {screen === "TypeSelection" ? (
        <Stack height={"max-content"} overflow={"auto"} gap={1}>
          {SelectionScreens.map(({ title, description, to }) => {
            return (
              <StyledFormBox
                color={"inherit"}
                key={to}
                onClick={() => changeScreenSelection(to)}
              >
                <Typography variant="h3" fontWeight={700} lineHeight={"1.2rem"}>
                  {title}
                </Typography>
                <Typography variant="caption" fontSize={14} fontWeight={500}>
                  {description}
                </Typography>
              </StyledFormBox>
            );
          })}
        </Stack>
      ) : screen === "ApplyTemplateToOrg" ? (
        <ApplyTemplateToOrg onClose={onClose} />
      ) : (
        <>
          {screen === "SessionSelection" ? (
            <SelectSessionForm
              orgId={orgId}
              isMovingTask={Boolean(movingTaskId)}
              taskId={movingTaskId}
              continueMovingFlow={continueMovingFlow}
              isNotSessionTask={isNotSessionTask}
            />
          ) : (
            <>
              {isTaskScreen ? (
                <AddTaskForm
                  onClose={onClose}
                  orgId={orgId}
                  open={true}
                  type={
                    screen === "AddCustomWork"
                      ? "custom_work"
                      : screen === "AddQuickQuestion"
                      ? "quick_question"
                      : screen === "AddInternalTask"
                      ? "internal_task"
                      : "task"
                  }
                  sessionId={sessionId}
                />
              ) : (
                <>
                  {["CreateSession", "CreatePendingSession", "Done"].includes(
                    screen,
                  ) ? (
                    <Stack direction={"column"} height={"100%"}>
                      <CreateSessionForm
                        createPendingSubmit={createPendingClick}
                        orgId={orgId}
                      />
                      {screen !== "CreatePendingSession" ? (
                        <>
                          <Divider sx={{ my: 2, width: "100%" }} />
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              changeScreenSelection(
                                movingTaskId ? "MoveTask" : "AddTask",
                                sessionId,
                              )
                            }
                            sx={{ width: "max-content", alignSelf: "flex-end" }}
                            disabled={screen !== "Done"}
                          >
                            Done
                          </Button>
                        </>
                      ) : null}
                    </Stack>
                  ) : screen === "MoveTaskComplete" ? (
                    <Stack
                      direction={"column"}
                      alignItems={"flex-start"}
                      gap={4}
                      p={2}
                    >
                      <Stack
                        direction={"row"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                        width={"100%"}
                        gap={1}
                      >
                        <Button
                          onClick={onClose}
                          variant="outlined"
                          color="white"
                        >
                          Close
                        </Button>
                        <Button onClick={goToSessionClick} variant="contained">
                          View session
                        </Button>
                      </Stack>
                    </Stack>
                  ) : screen === "RequestSubmitted" ? (
                    <Stack direction={"column"} alignItems={"center"} gap={4}>
                      <Typography variant="h1">
                        Your request has been submitted
                      </Typography>
                      <Stack direction={"row"} gap={2}>
                        <Button
                          onClick={onClose}
                          variant="outlined"
                          color="white"
                        >
                          Close
                        </Button>
                        {!!taskId && (
                          <Button
                            onClick={goToTaskAndClose}
                            variant="contained"
                          >
                            View task
                          </Button>
                        )}
                      </Stack>
                    </Stack>
                  ) : null}
                </>
              )}
            </>
          )}
        </>
      )}
    </Stack>
  );
};
