import { useCallback, useEffect, useRef, useState } from "react";
interface Props {
  direction: "up" | "down";
  start: boolean;
  defaultCount?: number;
}
export const useClock = ({ direction = "up", start, defaultCount }: Props) => {
  const [time, setTime] = useState<number>(defaultCount ?? 0);
  const intervalIdRef = useRef<number | undefined>();
  const stopClock = useCallback(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
  }, []);

  useEffect(() => {
    if (start) {
      intervalIdRef.current = setInterval(() => {
        setTime((old) => (direction === "up" ? old + 1 : old - 1));
      }, 1000) as unknown as number;
    } else {
      stopClock();
    }
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [direction, start, stopClock]);

  return { time, setTime, stopClock };
};
