import {
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useMobile } from "../../themes";
import { useState, useCallback, useMemo } from "react";
import { BaseModal, AddUserForm } from "../forms";
import { GetUserProfileApiResponse } from "../../state/rtk-query/state/user";
import { useOrgId } from "../hooks";
import { EditUnderlineIcon } from "../icons";
import { ProfileField } from "../misc";
import {
  CardContentContainerStyled,
  CardContentItemStyled,
  DarkGreyTextStyled,
} from "../styled";
import { formatPhone } from "../../lib";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { timezones } from "../../lib/timezones";
interface Props {
  data: GetUserProfileApiResponse | undefined;
}
interface ProfFieldProps {
  field: string;
  value?: string;
}
const SpaceProfileField = ({ field, value }: ProfFieldProps) => (
  <CardContentItemStyled item>
    <ProfileField field={field} value={value} />
  </CardContentItemStyled>
);
export const ProfileCard = ({ data }: Props) => {
  const { palette } = useTheme();
  const isMobile = useMobile();
  const orgId = useOrgId();
  const isInternal = useSelector(authSelectors.isInternal);
  const [openUserModal, setOpenUserModal] = useState(false);

  const editProfileClick = useCallback(() => {
    setOpenUserModal((openModal) => !openModal);
  }, []);

  const {
    title,
    first_name,
    last_name,
    availability,
    email,
    timezone,
    phones,
    orgs,
  } = data ?? {};

  const orgData = orgs?.map((o) => {
    return {
      name: o.name,
      orgId: o.id,
      position: o.user_roles[0]?.position_name ?? "",
      role: o.user_roles[0]?.role_name ?? "",
    };
  });
  const tz = timezones.find((tz) => tz.name === timezone)?.abbreviation;
  const TimeZoneField = useMemo(
    () => <SpaceProfileField field={"Timezone"} value={tz ?? ""} />,
    [timezone],
  );

  return (
    <>
      <Stack
        sx={{
          border: `1px solid ${palette.grey[500]}`,
          borderRadius: "6px",
          my: 2,
        }}
        direction={isMobile ? "column" : "row"}
        bgcolor={"#FFF"}
        p={isMobile ? 1 : 2}
        divider={
          isMobile ? (
            <Divider sx={{ width: "95%", alignSelf: "center" }} flexItem />
          ) : (
            <Divider
              orientation="vertical"
              sx={{ height: "95%", alignSelf: "center" }}
              flexItem
            />
          )
        }
      >
        <Box sx={{ p: 2, width: isMobile ? "100%" : "15%" }}>
          <Typography variant="h3">{"My profile"}</Typography>
        </Box>
        <Stack pl={3} width={isMobile ? "100%" : "85%"} direction={"column"}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction={"column"} width={"100%"}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
              >
                <Typography variant="h3">{"PERSONAL INFO"}</Typography>
                <IconButton onClick={editProfileClick}>
                  <EditUnderlineIcon />
                </IconButton>
              </Stack>
              <CardContentContainerStyled container>
                <SpaceProfileField
                  field={"Name"}
                  value={`${title ?? ""} ${first_name ?? ""} ${
                    last_name ?? ""
                  }`}
                />
                {isInternal ? (
                  <SpaceProfileField
                    field={"Availability"}
                    value={availability ?? ""}
                  />
                ) : (
                  TimeZoneField
                )}
              </CardContentContainerStyled>
              <CardContentContainerStyled container>
                <SpaceProfileField field={"Email"} value={email} />
                {isInternal ? TimeZoneField : null}
              </CardContentContainerStyled>
              <CardContentItemStyled item>
                <Stack
                  direction={isMobile ? "column" : "row"}
                  alignItems={"flex-start"}
                  py={1}
                  width={isMobile ? "100%" : "max-content"}
                  gap={0.5}
                >
                  <DarkGreyTextStyled
                    fontSize={isMobile ? 14 : 16}
                    width={isMobile ? 295 : 75}
                    fontWeight={500}
                  >
                    {"Phone"}
                  </DarkGreyTextStyled>
                  <Stack
                    direction={"column"}
                    gap={0.5}
                    width={isMobile ? "100%" : "max-content"}
                  >
                    {phones?.map((p) => (
                      <Typography
                        ml={0.5}
                        mr={0.5}
                        fontSize={isMobile ? 14 : 16}
                        fontWeight={500}
                        key={p.phone_number}
                      >
                        {formatPhone(p.phone_number, true)}
                        {p.is_primary ? " (Primary)" : ""}
                      </Typography>
                    ))}
                  </Stack>
                </Stack>
              </CardContentItemStyled>
              {orgData?.map((o) => (
                <Stack direction={"column"} pt={4} gap={1} key={o.orgId}>
                  <Typography variant="h3">{o.name ?? ""}</Typography>
                  <CardContentContainerStyled container>
                    <SpaceProfileField field={"Role"} value={o.role} />{" "}
                    <SpaceProfileField field={"Position"} value={o.position} />
                  </CardContentContainerStyled>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <BaseModal
        open={openUserModal}
        title={"Edit Profile"}
        onClose={editProfileClick}
      >
        <AddUserForm
          mode={"client"}
          onClose={editProfileClick}
          editUser={data}
          isUpdateProfile
          orgId={orgId}
        />
      </BaseModal>
    </>
  );
};
