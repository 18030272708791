import { AppThunk } from "../types";
import { timer, TimerState } from "./state";
const { actions } = timer;

export const timerActions = {
  ...actions,
  setTimerIdAction(id?: number) {
    return actions.setTimerId(id);
  },
  setTimerOrgIdAction(id?: number) {
    return actions.setTimerOrgId(id);
  },
  setTimerCwAction({
    taskId,
    taskTitle,
    taskCode,
    reset,
  }: {
    reset?: boolean;
    taskId?: number;
    taskCode?: string;
    taskTitle?: string;
  }) {
    return actions.setTimerCw(
      reset ? undefined : { taskCode, taskId, taskTitle },
    );
  },
  setTimerStateAction(state: TimerState["timer"]["state"]) {
    // if (state === "stopped") {
    //   return actions.setTimer({
    //     initializing: false,
    //     state,
    //     stayOnOrgId: undefined,
    //     cancelledSwitch: false,
    //   });
    // }
    return actions.setTimerState(state);
  },
  setTimerCancelledSwitchAction(): AppThunk<any> {
    return (dispatch) => {
      dispatch(actions.setTimerState("active"));
      dispatch(actions.setTimerCancelledSwitch(true));
    };
  },
  setTimerMidSwitchAction(midSwitch = false) {
    return actions.setTimerMidSwitch(midSwitch);
  },
};
