import { Button, Link, Stack, Typography } from "@mui/material";
import { AddIcon } from "../icons";
import { ChooseSessionCard } from "..";
import { useGetUpcomingSessionsQuery } from "../../state/rtk-query/state/session";
import { useCallback, useEffect } from "react";
import { clientActions, useDispatch } from "../../state";
import { Navigation } from "../../lib";
import { ClientState } from "../../state/client/state";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useMoveTaskToSessionMutation,
  useConvertTaskMutation,
} from "../../state/rtk-query/state/tasks";
import { useOrgId } from "../hooks";

interface Props {
  orgId: number;
  taskId?: number;
  isMovingTask?: boolean;
  onClose?: () => void;
  continueMovingFlow?: boolean;
  isNotSessionTask?: boolean;
}
export const SelectSessionForm = ({
  isMovingTask = false,
  taskId,
  orgId: _orgId,
  onClose,
  continueMovingFlow = false,
  isNotSessionTask,
}: Props) => {
  const dispatch = useDispatch();
  const [moveToSession] = useMoveTaskToSessionMutation();
  const [convertTask] = useConvertTaskMutation();
  const useOrgIdHook = useOrgId();
  const orgId = _orgId ?? useOrgIdHook;
  const { currentData: sessions, refetch } = useGetUpcomingSessionsQuery(
    orgId
      ? {
          orgId,
          includePending: true,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (isMovingTask) {
      refetch();
    }
  }, []);
  const changeScreenSelection = useCallback(
    (screen: ClientState["support"]["screen"], sessionId?: number) => {
      if (screen === "AddTask" && sessionId && !continueMovingFlow) {
        Navigation.go(`/sessions/${sessionId}`);
        dispatch(clientActions.setSupportSessionId(sessionId));
      }
      if (continueMovingFlow && sessionId) {
        dispatch(clientActions.setSupportSessionId(sessionId));
      }
      dispatch(clientActions.setSupportScreenAction(screen));
    },
    [onClose, taskId, isMovingTask, orgId],
  );
  const linkClicked = async (id?: number) => {
    if (id && !isMovingTask) {
      changeScreenSelection("AddTask", id);
    } else {
      if (taskId && id) {
        dispatch(clientActions.setSupportSessionId(id));
        const moveSuccess = await (isNotSessionTask
          ? convertTask({
              body: { session_id: id, type: "task" },
              id: taskId,
              orgId,
            })
          : moveToSession({
              id: taskId,
              orgId,
              sessionId: id,
            }));
        if (!("error" in moveSuccess)) {
          dispatch(clientActions.setSupportScreenAction("MoveTaskComplete"));
        }
      }
    }
  };

  const upcomingNotIncludingThisTask =
    taskId && sessions?.rows
      ? sessions?.rows.filter(
          (r) => !r.tasks?.map((t) => t.id).includes(taskId),
        )
      : sessions?.rows ?? [];

  return (
    <>
      <Stack
        direction="column"
        justifyContent={"space-between"}
        gap={1}
        maxHeight={"700px"}
        overflow={"auto"}
      >
        {upcomingNotIncludingThisTask?.map((uc) => (
          <Link key={uc.id} onClick={async () => await linkClicked(uc.id)}>
            <ChooseSessionCard session={uc} />
          </Link>
        ))}
      </Stack>
      <Button
        sx={{
          width: "fit-content",
          pl: 0,
        }}
        onClick={() => changeScreenSelection("CreateSession")}
      >
        <AddIcon fontSize="small" />
        <Typography fontSize={14} fontWeight={600}>
          New Session
        </Typography>
      </Button>
    </>
  );
};
