import {
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { AddIcon, BaseModal, ClockIcon, EditUnderlineIcon, Loading } from "..";
import { useGetTaskTimeSpentQuery } from "../../state/rtk-query/state/tasks";
import { useState } from "react";
import {
  castZero,
  convertToHoursAndMinutes,
  fixJsFormattedDate,
  formatDate,
  pluralize,
} from "../../lib";
import { authSelectors, useSelector } from "../../state";
import { BaseTimeEntryModal } from "../modal/BaseTimerModal";
interface Props {
  id: number;
  orgId: number;
  time_spent: number;
  hideModal?: boolean;
}
export const TimeSpentButton = ({
  id,
  orgId,
  time_spent,
  hideModal = false,
}: Props) => {
  const isInternal = useSelector(authSelectors.isInternal);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const userId = useSelector(authSelectors.userId);
  const { palette } = useTheme();
  const [openModal, setOpenModal] = useState(false);

  // const [timers, setTimers] = useState<GetTaskTimeSpentApiResponse["timers"]>(
  //   [],
  // );
  const { data: { timers = [] } = {}, isFetching } = useGetTaskTimeSpentQuery(
    { id },
    { skip: !openModal || !id },
  );

  const onClick = async () => {
    setOpenModal(!openModal);
  };
  const timeSpentOnTask = convertToHoursAndMinutes(time_spent, true);
  const [selectedTimer, setSelectedTimer] = useState<
    (typeof timers)[number] | undefined | true
  >();

  if (!isInternal) return null;

  return (
    <>
      <Button onClick={onClick} sx={{ p: 0 }} disabled={hideModal}>
        <ClockIcon color={palette.secondary[300]} size={16} />
        <Typography
          color={palette.secondary[600]}
          fontWeight={600}
          ml={0.5}
          fontSize={12}
        >
          {timeSpentOnTask}
        </Typography>
      </Button>
      {!hideModal && (
        <BaseModal
          open={openModal}
          onClose={onClick}
          title="Time spent"
          ButtonNearClose={
            <Button onClick={() => setSelectedTimer(true)} variant="outlined">
              <AddIcon style={{ fontSize: "1em" }} /> Manual Entry
            </Button>
          }
          modalProps={{
            sx: { height: "90% !important", overflow: "scroll !important" },
          }}
        >
          <Stack
            direction="column"
            gap={2}
            my={1}
            width={"100%"}
            divider={<Divider flexItem sx={{ width: "100%" }} />}
          >
            {isFetching ? <Loading /> : null}
            {timers.map((timer) => (
              <Stack
                direction="column"
                alignItems="flex-Start"
                gap={1}
                key={timer.id}
                width={"100%"}
              >
                <Typography fontWeight={600}>
                  {timer?.user?.first_name ?? ""} {timer?.user?.last_name ?? ""}{" "}
                  - {timer.activity_type?.name ?? ""}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <Typography>
                    {timer.duration && timer.duration_date
                      ? formatDate(fixJsFormattedDate(timer.duration_date))
                      : timer.start_time && timer.end_time
                      ? `${formatDate(
                          new Date(timer.start_time),
                          "MM/dd/yyyy · h:mm aaa",
                        )} - ${formatDate(
                          new Date(timer.end_time),
                          "hh:mm aaa",
                        )}`
                      : null}
                  </Typography>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Typography variant="subtitle2">
                      {Number(castZero(timer?.time_spent)) < 1
                        ? "< 1 minute"
                        : `${pluralize(
                            Number(castZero(timer?.time_spent).toFixed(2)),
                            "Minute",
                          )}`}
                    </Typography>{" "}
                    {(timer.user.id === userId || isAdmin) &&
                      timer?.status &&
                      !["old", "billed", "billing"].includes(timer?.status) && (
                        <IconButton
                          onClick={() => setSelectedTimer(timer)}
                          sx={{ ml: 2 }}
                        >
                          <EditUnderlineIcon size={16} />
                        </IconButton>
                      )}
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </BaseModal>
      )}
      <BaseTimeEntryModal
        open={!!selectedTimer}
        timer={selectedTimer === true ? undefined : selectedTimer}
        onClose={() => setSelectedTimer(undefined)}
        orgId={orgId}
        taskId={id}
      />
    </>
  );
};
