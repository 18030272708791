import React, { useEffect } from "react";
import { Box, Typography, TextField, Button, Link } from "@mui/material";
import { Form, useFormik, yup, RouterLink } from "../../lib";
import {
  ContainerStyle,
  FormStyle,
  TextFlexStyle,
  CenterElementStyle,
  ButtonStyle,
} from "./Auth.styles";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, User, authActions } from "../../state";
import { Checkbox } from "formik-mui";
import { Field } from "formik";
import { VITE_MFA_REMEMBER_DAYS } from "../../config";
import { actions } from "../../state/rtk-query";

const validationSchema = yup.object({
  mfaCode: yup.string().required("Multifactor authentication code is required"),
});

export const MultifactorAuthPage = React.memo(
  /**
   *
   */
  function MultifactorAuthPage() {
    const { useAuthMultiFactorAuthMutation, useLazyAuthResendMfaCodeQuery } =
      actions;
    const [authMfa, { isSuccess, data }] = useAuthMultiFactorAuthMutation();
    const [resendMfaQuery] = useLazyAuthResendMfaCodeQuery();
    const dispatch = useDispatch<AppThunkDispatch>();

    const frm = useFormik({
      initialValues: { mfaCode: "", rememberMe: false },
      validationSchema,
      onSubmit(values) {
        authMfa({ body: values });
      },
    });

    useEffect(() => {
      if (isSuccess && data) {
        const { user, token } = data;
        if (user && token) {
          dispatch(
            authActions.load({
              user: user as User,
              token,
            }),
          );
        }
      } else if (!isSuccess) {
        frm.setFieldValue("mfaCode", undefined);
      }
    }, [isSuccess]);

    const resendMfa = (type: "email" | "sms" = "sms") => {
      frm.setFieldValue("mfaCode", undefined);
      resendMfaQuery({ type });
    };
    return (
      <ContainerStyle>
        <Box>
          <Typography variant="h3" gutterBottom fontWeight={600}>
            Multifactor Authentication
          </Typography>

          <Typography variant="body1" gutterBottom fontWeight={500}>
            Enter the multifactor authentication code that was sent to your
            device. If you have not set up a device, reach out to the office
          </Typography>

          <Button
            variant="outlined"
            type="button"
            onClick={() => resendMfa("email")}
            fullWidth
          >
            Use email verification instead
          </Button>

          <FormStyle>
            <Form form={frm}>
              <TextFlexStyle>
                <TextField
                  autoFocus
                  fullWidth
                  type={"string"}
                  id="mfaCode"
                  name="mfaCode"
                  label="Multifactor authentication code"
                  value={frm.values.mfaCode || ""}
                  onChange={frm.handleChange}
                  error={frm.touched.mfaCode && Boolean(frm.errors.mfaCode)}
                  helperText={frm.touched.mfaCode && frm.errors.mfaCode}
                  autoComplete="off"
                />
              </TextFlexStyle>
              <Typography>
                <Field component={Checkbox} type="checkbox" name="rememberMe" />
                {`Remember me for ${VITE_MFA_REMEMBER_DAYS} days`}
              </Typography>
              <ButtonStyle
                variant="contained"
                type="submit"
                disabled={frm.isSubmitting || !frm.values.mfaCode}
                fullWidth
              >
                Verify Code
              </ButtonStyle>
              <ButtonStyle
                variant="outlined"
                type="button"
                onClick={() => resendMfa()}
                fullWidth
              >
                Resend Code
              </ButtonStyle>
              <CenterElementStyle>
                <Link component={RouterLink} to={`/auth/login`}>
                  <Typography variant="body2"> Back to login </Typography>
                </Link>
              </CenterElementStyle>
            </Form>
          </FormStyle>
        </Box>
      </ContainerStyle>
    );
  },
);
