/** @file this hook is in charge of setting inferredOrgId and open/close tabs */
import { useCallback, useEffect, useMemo } from "react";
import {
  authSelectors,
  timerActions,
  timerSelectors,
  useDispatch,
  useSelector,
} from "../../state";
// import { useUpdateOpenTabsMutation } from "../../state/rtk-query/state/timer";
import { matchPath, useLocation } from "react-router-dom";
import { MainPages } from "../../pages";

export const useTimerSetter = () => {
  const dispatch = useDispatch();
  // const [updateOpenTabs] = useUpdateOpenTabsMutation();
  const isCw = useSelector(authSelectors.isCustomWorkUser);
  const taskOrgId = useSelector(timerSelectors.taskOrgId);
  const location = useLocation();
  const isInternal = useSelector(authSelectors.isInternal) && !isCw;
  // const { orgId: timerOrgId } = useSelector(timerSelectors.currentTimer);
  const inferredOrgId = useSelector(timerSelectors.inferredOrgId);
  const orgId = useMemo(() => {
    const pathMatch = matchPath(
      MainPages(true).organization.path,
      location.pathname,
    );
    return taskOrgId || (pathMatch ? Number(pathMatch.params.id) : undefined);
  }, [taskOrgId, location.pathname]);

  const checkTimerInferredOrgId = useCallback(() => {
    if (!isInternal || !orgId) return;
    if (inferredOrgId !== orgId) {
      dispatch(timerActions.setTimerInferredOrgId(orgId));
    }
  }, [isInternal, orgId, inferredOrgId, dispatch]);
  useEffect(() => {
    console.log("Run checkTimerInferredOrgId");
    checkTimerInferredOrgId();
  }, [checkTimerInferredOrgId, location]);
  // useEffect(() => {
  //   if (!isInternal || !orgId) return;
  //   if (document.visibilityState === "visible") {
  //     updateOpenTabs({ orgId, tabCountDirection: "increment" });
  //   } else {
  //     // in a switching state
  //     if (![timerOrgId, stayOnOrgId].includes(orgId)) {
  //       updateOpenTabs({ orgId, tabCountDirection: "decrement" });
  //     }
  //   }
  // }, [orgId, isInternal, updateOpenTabs, timerOrgId, stayOnOrgId]);

  return { checkTimerInferredOrgId };
};
