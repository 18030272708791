import { useDispatch, useSelector } from "react-redux";
import { authSelectors, timerActions, timerSelectors } from "../../state";
import {
  useCreateTimerMutation,
  useLazyGetMyActiveTimerQuery,
  useStopActiveTimerMutation,
} from "../../state/rtk-query/state/timer";
import { useActivityTypesList } from "./useLists";
import { matchPath } from "react-router-dom";
import { MainPages } from "../../pages";
import { useCallback } from "react";
import { useLocation } from "../../lib/routing/Navigation";

interface StartTimerProps {
  org_id: number;
  task_id?: number;
  task_code?: string;
  task_title?: string;
  manualStarted?: boolean;
}
let timerStarting = false;
export const useStartTimer = (activityTypeId?: number) => {
  const dispatch = useDispatch();
  const [createTimer] = useCreateTimerMutation();
  const { getActivityId } = useActivityTypesList();
  const [getMyActiveTimer] = useLazyGetMyActiveTimerQuery();
  const { query } = useLocation();
  const isIt = useSelector(authSelectors.isIT);
  const isInternal = useSelector(authSelectors.isInternal);
  const isConsultant = useSelector(authSelectors.isConsultant);
  const isCustomWorkUser = useSelector(authSelectors.isCustomWorkUser);
  const { state, initializing } = useSelector(timerSelectors.currentTimer);
  const manualStopped = useSelector(timerSelectors.manualStopped);
  const isSessionPage = matchPath(
    MainPages(true).session.path,
    location.pathname,
  );
  const activityId = getActivityId(
    isConsultant
      ? "tasks"
      : isCustomWorkUser
      ? "custom_work"
      : isIt
      ? "it"
      : isSessionPage
      ? "sessions"
      : "tasks",
  );
  const activity_type_id = activityTypeId || activityId;

  const startTimer = useCallback(
    async ({
      org_id,
      task_id: _taskId,
      task_code: taskCode,
      task_title: taskTitle,
      manualStarted = false,
    }: StartTimerProps) => {
      if (!isInternal) return;
      if (manualStopped && manualStarted === false) {
        console.info("Wont start timer since was manually stopped");
        return;
      }

      const taskInfo = { taskId: _taskId || query.task, taskCode, taskTitle };
      if (isCustomWorkUser && !taskInfo.taskId) return;

      console.log("start_timer", { state });
      if (timerStarting === true) {
        console.error("Timer already starting");
        //In case something blows up, reset in 5 sec
        setTimeout(() => {
          (timerStarting = false), 5000;
        });
        return;
      }
      if (state === "active") {
        console.error("Can't start timer, already active");
        return;
      }
      if (initializing === true) {
        console.error(
          "Do not start timer before we get details from backend - fetching",
        );
        await getMyActiveTimer();
        return;
      }
      timerStarting = true;
      const newTimer = await createTimer({
        body: {
          org_id,
          activity_type_id: activity_type_id,
          task_id: taskInfo.taskId ? Number(taskInfo.taskId) : undefined,
        },
      }).unwrap();

      if (newTimer) {
        dispatch(
          timerActions.setTimer({
            initializing: false,
            state: "active",
            id: newTimer.id,
            orgId: org_id,
            customWork: taskInfo,
          }),
        );
      }
      timerStarting = false;
      //Remove the manual stop so that going forward it should auto start
      dispatch(timerActions.setManualStopped(false));
    },
    [
      isInternal,
      manualStopped,
      query.task,
      isCustomWorkUser,
      state,
      initializing,
      createTimer,
      activity_type_id,
      getMyActiveTimer,
      dispatch,
    ],
  );
  return startTimer;
};

export const useRestartTimer = () => {
  const dispatch = useDispatch();
  const [stopTimer] = useStopActiveTimerMutation();
  const startTimer = useStartTimer();
  const { orgId } = useSelector(timerSelectors.currentTimer);
  const inferredOrgId = useSelector(timerSelectors.inferredOrgId);
  const manualStopped = useSelector(timerSelectors.manualStopped);

  const org = inferredOrgId ?? orgId;
  if (!org) return;
  const restart = async (manualStarted = false) => {
    if (manualStopped && manualStarted === false) {
      console.info("Wont restart timer since was manually stopped");
      return;
    }

    if (startTimer && org) {
      dispatch(timerActions.setTimerMidSwitchAction(true));
      await stopTimer();
      await startTimer({
        org_id: org,
      });
      dispatch(timerActions.setTimerMidSwitchAction());
    }
    //Remove the manual stop so that going forward it should auto start
    dispatch(timerActions.setManualStopped(false));
  };
  return restart;
};

export const useStopTimer = () => {
  const dispatch = useDispatch();
  const [stopTimer] = useStopActiveTimerMutation();
  const { state } = useSelector(timerSelectors.currentTimer) ?? {};
  const { pathname } = useLocation();

  const stop = useCallback(
    async (manualStop = false) => {
      if (manualStop) {
        dispatch(timerActions.setTimerCancelledSwitch(false));
        dispatch(timerActions.setManualStopped(true));
      }
      const pathMatch = matchPath(MainPages(true).organization.path, pathname);

      console.log("stopped => ", { pathMatch });
      if (state && state === "stopping") {
        await stopTimer();
        dispatch(timerActions.setTimerStateAction("stopped"));
        if (pathMatch && pathMatch.params.id) {
          dispatch(timerActions.setTimerOrgId(Number(pathMatch.params.id)));
        } else {
          console.log("Resetting timer org and inferred org");
          dispatch(timerActions.resetTimer());
        }
      } else if (state && state === "active") {
        dispatch(timerActions.setTimerStateAction("stopping"));
      }
    },
    [pathname, state, dispatch, stopTimer],
  );
  return stop;
};

export const useTimerActions = (activityId?: number) => {
  const restartTimer = useRestartTimer();
  const startTimer = useStartTimer(activityId);
  const stopTimer = useStopTimer();
  return { restartTimer, startTimer, stopTimer };
};
