import {
  Box,
  Divider,
  Pagination,
  Stack,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useGetOrgTaskMediaQuery } from "../../../state/rtk-query/state/tasks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  GetSignedUrlByMediaIdApiResponse,
  useLazyGetSignedUrlByMediaIdQuery,
} from "../../../state/rtk-query/state/media";
import { AppIcon, ListIcon, ServerDataGrid } from "../../../components";
import {
  useOrgId,
  useQuery,
  useRtkQueryParams,
} from "../../../components/hooks";
import { useMobile } from "../../../themes";
import { useMediaColumns } from "../../../columns";
import { skipToken } from "@reduxjs/toolkit/query";
import { MediaDrawer } from "../../../components/drawer/media";
import { useSelector } from "react-redux";
import { authSelectors } from "../../../state";
import {
  DisplayMediaImages,
  Emptiable,
  Loadable,
} from "../../../components/misc";
const tabs = ["custom_work", "other"] as const;
type TabType = (typeof tabs)[number];
type ViewType = "image" | "list";
type ImageInfo = GetSignedUrlByMediaIdApiResponse & { filename: string };
interface QueryProps {
  mediaTab: TabType;
  viewType: ViewType;
  media?: number;
  page?: number;
}

const PAGE_LIMIT = 25;
export const MediaPage = ({ orgId: _orgId }: { orgId?: number }) => {
  const isInternal = useSelector(authSelectors.isInternal);
  const orgHook = useOrgId();
  const orgId = isInternal ? _orgId : orgHook;
  const isMobile = useMobile();
  const [query, setQuery] = useQuery<QueryProps>(
    "mediaTab",
    "viewType",
    "media",
    "page",
  );

  const [mediaParams, setParams] = useRtkQueryParams({ orgId });
  const { mediaTab, viewType, media } = query;
  const [images, setImages] = useState<ImageInfo[] | undefined>();
  const [getMediaSignedUrl, { isFetching: fetchingImage }] =
    useLazyGetSignedUrlByMediaIdQuery();
  const { page } = query ?? {};
  const params = useMemo(
    () => ({
      ...mediaParams,
      range:
        viewType === "list"
          ? mediaParams.range
          : [page ? page - 1 : 0, PAGE_LIMIT],
      orgId: orgId ?? 0,
      type: mediaTab || "other",
    }),
    [orgId, mediaParams, mediaTab, page, viewType],
  );

  const { currentData, isFetching, isSuccess } = useGetOrgTaskMediaQuery(
    params ? params : skipToken,
    // signed url expires after 15 minutes in the BE so if a user is on this page longer than that we should refetch
    { refetchOnMountOrArgChange: true, pollingInterval: 1000 * 60 * 15 },
  );

  const hasCW = currentData?.rows.find((r) => r?.is_custom_work_manual);

  useEffect(() => {
    if (!mediaTab) setQuery({ mediaTab: "other" });
    if (!viewType) setQuery({ viewType: "list" });
  }, [mediaTab, viewType]);

  useEffect(() => {
    (async () => {
      if (!currentData?.rows || !viewType) {
        setImages(undefined);
        return;
      }

      const getSignedUrlData = await Promise.all(
        currentData?.rows?.map(async (s) => {
          if (s?.media_id && orgId) {
            const media = await getMediaSignedUrl({
              id: s?.media_id,
              download: false,
              compact: viewType !== "list",
              orgId,
              pdfImg: viewType !== "list",
            }).unwrap();
            return { ...media, filename: s.media?.filename };
          }
        }),
      );
      const dataImages = getSignedUrlData.filter(
        (s): s is ImageInfo => typeof s !== "boolean",
      );
      setImages(() => dataImages);
    })();
  }, [currentData, viewType]);

  const handleChange = useCallback(
    (_event: React.SyntheticEvent, newValue: TabType) => {
      setQuery({ mediaTab: newValue });
    },
    [media, mediaTab, viewType],
  );

  const handleView = useCallback(
    (_event: React.MouseEvent<HTMLElement>, newView: ViewType) => {
      if (newView !== null) {
        setQuery({ viewType: newView });
      }
    },
    [media, mediaTab, viewType],
  );

  const imgClick = useCallback(
    (id: number) => {
      setQuery({ media: id });
    },
    [media, mediaTab, viewType, page],
  );

  const activeMedia = useMemo(
    () => currentData?.rows?.find((r) => r?.media_id === media),
    [media, currentData?.rows?.length, mediaTab, viewType],
  );

  const dataWithSignedUrl = useMemo(() => {
    return currentData?.rows?.map((r) => {
      const findMedia = images?.find((i) => i.mediaId === r.media_id);
      return {
        ...r,
        signedUrl: findMedia?.signedUrl ?? "",
        ogExt: findMedia?.originalExt ?? "",
      };
    });
  }, [media, currentData?.rows.length, mediaTab, viewType, images]);

  const columns = useMediaColumns(isMobile, !!dataWithSignedUrl);
  const noMedia =
    isSuccess &&
    (!currentData?.rows?.length || (mediaTab === "custom_work" && !hasCW));
  return (
    <Stack direction={"column"} justifyContent={"flex-start"} height={"100%"}>
      <Stack direction={"row"} width="100%" justifyContent={"flex-end"}>
        <ToggleButtonGroup
          value={viewType}
          exclusive
          onChange={handleView}
          size="small"
          color="primary"
        >
          <ToggleButton value="image">
            <AppIcon />
          </ToggleButton>

          <ToggleButton value="list">
            <ListIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        value={mediaTab || (hasCW ? "custom_work" : "other")}
        onChange={handleChange}
      >
        <Tab
          key={"custom_work"}
          label={"Custom work"}
          value={"custom_work"}
          sx={{
            whiteSpace: "nowrap",
            fontSize: isMobile ? "16px" : "1em",
          }}
        />

        <Tab
          key={"other"}
          label={"Other attachments"}
          value={"other"}
          sx={{ whiteSpace: "nowrap", fontSize: isMobile ? "16px" : "1em" }}
        />
      </Tabs>
      <Divider flexItem sx={{ mb: 2, width: "100%" }} />
      {tabs.map((t) => {
        return (
          <Box hidden={mediaTab !== t} key={t} width={"100%"} height={"100%"}>
            {mediaTab === t && (
              <Stack width={"100%"} height={"100%"}>
                {viewType === "image" ? (
                  <Stack
                    width={"100%"}
                    minHeight={"100%"}
                    direction={"column"}
                    alignItems={"flex-end"}
                  >
                    {" "}
                    <Stack
                      gap={2}
                      width={"100%"}
                      height={"100%"}
                      direction={"row"}
                      flexWrap={"wrap"}
                      justifyContent={noMedia ? "center" : "flex-start"}
                    >
                      <Loadable isLoading={isFetching}>
                        <Emptiable
                          type={
                            mediaTab === "custom_work" && !hasCW
                              ? "cw_docs"
                              : "documents"
                          }
                          hasFilters={false}
                          isEmpty={noMedia}
                          orgId={orgId}
                        >
                          <DisplayMediaImages
                            images={images}
                            fetchingImage={fetchingImage}
                            onClick={imgClick}
                          />
                        </Emptiable>
                      </Loadable>
                    </Stack>
                    <Pagination
                      count={Math.ceil(
                        Number(currentData?.count ?? 0) / PAGE_LIMIT,
                      )}
                      onChange={(e, p) => {
                        setQuery({ page: p });
                      }}
                      page={page || 1}
                      color="primary"
                      shape="rounded"
                      size={isMobile ? "small" : "medium"}
                      sx={{
                        "& .MuiPaginationItem-root": {
                          fontSize: "1em",
                          "& .MuiSvgIcon-root": {
                            fontSize: "1em",
                          },
                        },
                      }}
                    />
                  </Stack>
                ) : (
                  <Stack
                    px={2}
                    height="100%"
                    width="100%"
                    direction={"row"}
                    minHeight={"100%"}
                  >
                    <ServerDataGrid
                      toolbarProps={{ hideToolbar: true }}
                      isFetching={isFetching}
                      rowCount={currentData?.count ?? 0}
                      rows={dataWithSignedUrl ?? []}
                      columns={columns}
                      setQueryParams={setParams}
                      currentParams={mediaParams}
                      props={{
                        disableRowSelectionOnClick: true,
                      }}
                    />
                  </Stack>
                )}
              </Stack>
            )}
          </Box>
        );
      })}
      {!!media && (
        <MediaDrawer
          orgId={orgId}
          media={activeMedia?.media}
          task={activeMedia?.task}
        />
      )}
    </Stack>
  );
};
