import React, { useCallback, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { Route, Routes } from "react-router-dom";
import { OneTap } from "./components/buttons/GoogleOneTap";
import { AuthLayout, MainLayout } from "./layouts";
import { renderPageRoutes } from "./lib";
import { HistoryRouter } from "./lib/routing/HistoryRouter";
import { AuthPages } from "./pages/auth";
import { MainPages } from "./pages/main";
import {
  authActions,
  authSelectors,
  // timerActions,
  // timerSelectors,
  useAction,
  // useDispatch,
  useSelector,
} from "./state";
import { useGetGoogleCredentialsQuery } from "./state/rtk-query/state/google";
import { useLazyGetMyActiveTimerQuery } from "./state/rtk-query/state/timer";

const loadScript = (src: string) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve(true);
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });

export const App = React.memo(function App() {
  const login = useAction(authActions.socialLogin);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const { currentData } = useGetGoogleCredentialsQuery();
  const isInternal = useSelector(authSelectors.isInternal);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const user = useSelector(authSelectors.userInfo);
  const pages = MainPages(isInternal, isAdmin);
  const [getMyActiveTimer] = useLazyGetMyActiveTimerQuery();
  useEffect(() => {
    if (isInternal) {
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "visible") {
          getMyActiveTimer();
        }
      });
    }
    return () => document.removeEventListener("visibilitychange", () => {});
  }, [getMyActiveTimer, isInternal]);

  const handleCredentialResponse = useCallback(
    async function (response: { credential: any }) {
      await login({ credential: response.credential, sign_in_type: "google" });
    },
    [login],
  );

  useEffect(() => {
    if (user?.email) {
      Sentry.setUser({
        email: user.email,
        username: `${user?.first_name} ${user?.last_name}`,
      });
    }
  }, [user.email, user?.first_name, user?.last_name]);

  useEffect(() => {
    const src = "https://accounts.google.com/gsi/client";
    if (!currentData?.client_id) return;
    loadScript(src)
      .then(() => {
        const g = window.google;
        g?.accounts?.id.initialize({
          client_id: currentData.client_id,
          callback: handleCredentialResponse,
        });
      })
      .catch(console.error);
  });

  return (
    <HistoryRouter>
      {!isLoggedIn && <OneTap />}
      <Routes>
        <Route path="/auth" element={<AuthLayout />}>
          {renderPageRoutes(AuthPages)}
        </Route>
        <Route path="/" element={<MainLayout />}>
          {renderPageRoutes(pages)}
        </Route>
      </Routes>
    </HistoryRouter>
  );
});
