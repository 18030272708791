import { AvatarGroup } from "./Avatar";
import { GetSessionApiResponse } from "../../state/rtk-query/state/session";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useMobile } from "../../themes";

export function SessionAvatarGroup({
  session,
  viewer,
  setOpenAttendeeModal,
}: {
  session?: GetSessionApiResponse;
  viewer?: "internal" | "external";
  setOpenAttendeeModal?: Dispatch<SetStateAction<boolean>>;
}) {
  if (!session) return null;
  return (
    <SessionAvatarGroupContent
      session={session}
      viewer={viewer}
      setOpenAttendeeModal={setOpenAttendeeModal}
    />
  );
}

function SessionAvatarGroupContent({
  session,
  viewer,
  setOpenAttendeeModal,
}: {
  session: GetSessionApiResponse;
  viewer?: "internal" | "external";
  setOpenAttendeeModal?: Dispatch<SetStateAction<boolean>>;
}) {
  const isMobile = useMobile();
  const { session_invitees } = session;
  const clientAssignee =
    session?.client_assignee_user ?? session?.created_by_user;
  const users = useMemo(
    () => [
      { ...session.assigned_to_user, label: "Admire assignee" },
      { ...clientAssignee, label: "Client assignee" },
      ...(session_invitees || [])
        .map((u) =>
          u.user?.length ? u.user : { first_name: "", last_name: u.email },
        )
        .flat()
        .sort((a: any, b: any) => +!!b?.id - +!!a?.id),
    ],
    [session.assigned_to_user, clientAssignee, session_invitees],
  );

  return (
    <AvatarGroup
      users={users}
      dimensions={24}
      maxBeforeGrouping={viewer === "external" || isMobile ? 2 : 6}
      labelForRemaining="Invitees"
      defaultLabel={"Invitee"}
      setOpenModal={setOpenAttendeeModal}
      spacing={viewer === "external" ? 0.5 : -1}
    />
  );
}
