import { Typography, Stack, Button } from "@mui/material";
import {
  AddIcon,
  AddOrEditOrgModal,
  CardStyled,
  ConfirmDeleteDialog,
} from "../../../components";
import { useEffect, useMemo, useState } from "react";
import { authSelectors, useSelector } from "../../../state";
import { useOrgColumns } from "../../../columns";
import {
  useBulkDeleteOrgsSubConsultantMutation,
  useUpdateOrgsConsultantMutation,
} from "../../../state/rtk-query/state/organization";
import { BaseForm, BaseModal } from "../../../components/forms";
import { TopLabel, SelectField } from "../../../components/forms/FormFields";
import { useQueryParam } from "../../../components/hooks";
import { useConsultantsList } from "../../../components/hooks/useLists";
import { ToggleDropdown } from "../../../components/buttons/ToggleDropdown";
import RowsSelectedBanner from "../../../components/cards/RowsSelectedBanner";
import { useMobile } from "../../../themes";
import { useRowSelectionModel } from "../../../components/hooks/useRowSelectionModel";
import { Searchbar } from "../../../components/inputs/Searchbar";
import { useList } from "../../../components/hooks/useNewLists";
import { GridFilterModel } from "@mui/x-data-grid";
import { BaseDataGrid } from "../../../components/tables/BaseDataGrid";

export const OrganizationsPage = () => {
  const isMobile = useMobile();
  const isAdmin = useSelector(authSelectors.isAdmin);
  const [inactive, setInactive] = useQueryParam<boolean | undefined>(
    "inactive",
  );
  const orgs = useList("orgs");

  const {
    onCellClick,
    onColumnHeaderClick,
    selectedRows,
    setSelectedRows,
    onRowClick,
  } = useRowSelectionModel(orgs.list);

  const columns = useOrgColumns();
  const [openConsultantModal, setOpenConsultantModal] = useState(false);

  const [removeSubConsultants, { isSuccess }] =
    useBulkDeleteOrgsSubConsultantMutation();

  useEffect(() => {
    if (isSuccess) {
      setSelectedRows([]);
    }
  }, [isSuccess]);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    quickFilterExcludeHiddenColumns: true,
  });
  const [openAddOrg, setOpenAddOrg] = useState(false);

  const orgsToUse = useMemo(
    () => orgs.list.filter((o) => !o.deleted_at === !inactive),
    [orgs.list, inactive],
  );

  return (
    <>
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        flexWrap={"wrap"}
        mb={3}
      >
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <Typography variant="h2" fontWeight="bold">
            Organizations
          </Typography>
          <ToggleDropdown
            options={["active", "inactive"]}
            setValue={(val) => setInactive(val === "inactive" || undefined)}
            value={inactive ? "inactive" : "active"}
          />
        </Stack>
        <Stack
          direction={"row"}
          gap={2}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Stack alignItems={"center"} width="300px">
            <Searchbar
              setSearch={(v) =>
                setFilterModel({
                  ...filterModel,
                  quickFilterValues: v ? [v] : undefined,
                })
              }
              search={filterModel.quickFilterValues?.[0]}
              placeholder="Search orgs"
            />
          </Stack>
          <Button
            variant="contained"
            onClick={() => setOpenAddOrg(!openAddOrg)}
          >
            <AddIcon style={{ fontSize: isMobile ? "1rem" : ".8rem" }} />
            Organization
          </Button>
        </Stack>
      </Stack>
      <RowsSelectedBanner
        selectedRowsCt={selectedRows.length}
        onClear={() => setSelectedRows([])}
        onSelectAll={() => setSelectedRows(orgs.list.map((r) => r.id) || [])}
      >
        {isAdmin && (
          <Button
            color="info"
            sx={{ whiteSpace: "nowrap" }}
            onClick={() => setOpenConsultantModal(!openConsultantModal)}
          >
            Edit Consultant
          </Button>
        )}
        {isAdmin && (
          <ConfirmDeleteDialog
            buttonText="Remove Sub-Consultants"
            buttonColor="info"
            handleConfirm={() =>
              removeSubConsultants({ body: { orgs: selectedRows } })
            }
            titleText="Remove Sub-Consultants"
            subTitleText="Are you sure you want to remove these sub-consultants?"
          />
        )}
      </RowsSelectedBanner>

      <CardStyled sx={{ height: "100%", mb: 2 }}>
        <BaseDataGrid
          rows={orgsToUse}
          columns={columns}
          onRowClick={onRowClick}
          onCellClick={onCellClick}
          checkboxSelection={isAdmin && !inactive}
          onColumnHeaderClick={onColumnHeaderClick}
          rowSelectionModel={selectedRows}
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
            columns: { columnVisibilityModel: { sub_consultant: false } },
          }}
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
        />
      </CardStyled>
      <AddOrEditOrgModal
        onClose={() => setOpenAddOrg(false)}
        open={openAddOrg}
      />
      <BaseModal
        open={openConsultantModal}
        title="Edit Consultant"
        onClose={() => {
          setOpenConsultantModal(!openConsultantModal);
        }}
      >
        <EditConsultantForm
          selectedRows={selectedRows}
          onClose={() => {
            setOpenConsultantModal(!openConsultantModal);
            setSelectedRows([]);
          }}
        />
      </BaseModal>
    </>
  );
};

type EditConsultantFormProps = {
  selectedRows: number[];
  onClose: () => void;
};
function EditConsultantForm({
  selectedRows,
  onClose,
}: EditConsultantFormProps) {
  const { consultants: _c } = useConsultantsList();
  const consultants = useMemo(() => _c.filter((c) => !c.deleted_at), [_c]);

  const initialValues = {
    consultant_id: "" as any as number,
    sub_consultant_id: "" as any as number,
  };
  const [updateOrgsConsultant] = useUpdateOrgsConsultantMutation();
  const onSubmit = ({
    consultant_id,
    sub_consultant_id,
  }: typeof initialValues) => {
    if (consultant_id || sub_consultant_id) {
      updateOrgsConsultant({
        body: {
          orgs: selectedRows,
          consultant_id: consultant_id || undefined,
          sub_consultant_id: sub_consultant_id || undefined,
        },
      });
    }
    onClose();
  };
  return (
    <BaseForm
      onClose={onClose}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {() => (
        <>
          <TopLabel label="Consultant" fullWidth sx={{ my: 1 }}>
            <SelectField
              name="consultant_id"
              items={consultants.map((c) => ({
                id: c.id,
                name: `${c.first_name} ${c.last_name}`,
              }))}
              fullWidth
            />
          </TopLabel>
          <TopLabel label="Sub Consultant" fullWidth sx={{ my: 1 }}>
            <SelectField
              name="sub_consultant_id"
              items={consultants.map((c) => ({
                id: c.id,
                name: `${c.first_name} ${c.last_name}`,
              }))}
              fullWidth
            />
          </TopLabel>
        </>
      )}
    </BaseForm>
  );
}
