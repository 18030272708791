import { Stack, useTheme, IconButton, Link, Tooltip, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clientSelectors, timerSelectors, uiActions } from "../../../state";
import { memo, useCallback, useEffect, useState } from "react";
import { useMobile } from "../../../themes";

import { useTimerListener } from "../../../components/hooks/useTimerListener";
import { useTimerSetter } from "../../../components/hooks/useTimerSetter";
import { Clock } from "../../../components/timer/Clock";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useGetMyActiveTimerQuery } from "../../../state/rtk-query/state/timer";
import { RouterLink } from "../../../lib/routing/Navigation";
import { MainPages } from "../../../pages/main";

export const TimerBar = memo(() => {
  const { checkTimerInferredOrgId } = useTimerSetter();
  useTimerListener();
  const isMobile = useMobile();
  const { palette } = useTheme();
  const [bgColor, setBgColor] = useState(palette.primary.main);
  const isDrawer = useSelector(clientSelectors.taskPreference) === "drawer";
  const initializing = useSelector(timerSelectors.initializing);
  const timerId = useSelector(timerSelectors.timerId);
  const timerState = useSelector(timerSelectors.timerState);
  const { refetch } = useGetMyActiveTimerQuery(undefined, {
    refetchOnMountOrArgChange: false,
    refetchOnFocus: false,
    refetchOnReconnect: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        checkTimerInferredOrgId();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () =>
      document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, [checkTimerInferredOrgId]);
  const handleRefreshClick = useCallback(async () => {
    await refetch();
    dispatch(uiActions.showSuccess("Timer refreshed from database"));
  }, [dispatch, refetch]);
  return (
    <Stack
      direction={"row"}
      alignItems={isMobile ? "flex-start" : "center"}
      justifyContent={"space-between"}
      position={"sticky"}
      top={0}
      bgcolor={bgColor}
      color={"#FFF"}
      py={isMobile ? 1 : 0}
      px={isMobile ? 1 : 2}
      sx={(theme) => ({
        zIndex:
          Number(
            isDrawer ? theme.zIndex.drawer ?? 0 : theme.zIndex.modal ?? 0,
          ) + Number(timerId && timerState === "active" ? 3 : 1), // on top of the drawer if there is a timer running
        transition: theme.transitions.create(["height", "display"], {
          duration: theme.transitions.duration.enteringScreen,
        }),
        height: initializing ? 0 : isMobile ? "100%" : "48px",
        display: initializing ? "none !important" : "flex !important",
      })}
      gap={1}
    >
      <Clock setBgColor={setBgColor} />
      <Box sx={{ display: "flex" }}>
        <Link
          to={MainPages(true).timer.path}
          component={RouterLink}
          sx={{
            display: "flex",
            fontSize: "16px",
            fontWeight: 600,
            alignItems: "center",
            justifyContent: "center",
            marginRight: 2,
            width: 92,
          }}
          color={"#FFF"}
        >
          View entries
        </Link>
        {!isMobile && (
          <IconButton onClick={handleRefreshClick}>
            <Tooltip title="Refresh Timer">
              <RefreshIcon sx={{ fill: "#FFFFFF", fontSize: 24 }} />
            </Tooltip>
          </IconButton>
        )}
      </Box>
    </Stack>
  );
});
