import { QueryDefinition, skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";
import {
  useAccountManagersListQuery,
  useConsultantsListQuery,
  useOrgsListQuery,
  useActivityActionsListQuery,
  useActivityTypesListQuery,
  useBookingTypesListQuery,
  useClientUsersListQuery,
  useLicensesListQuery,
  useLicenseStatusesListQuery,
  usePositionsListQuery,
  useStatusesListQuery,
  useTermItemTypesListQuery,
  useTopicsListQuery,
  useTemplatesListQuery,
} from "../../state/rtk-query/state/lists";
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";

const lists = {
  accountManagers: useAccountManagersListQuery,
  consultants: useConsultantsListQuery,
  activityActions: useActivityActionsListQuery,
  activityTypes: useActivityTypesListQuery,
  bookingTypes: useBookingTypesListQuery,
  clientUsers: useClientUsersListQuery,
  licenses: useLicensesListQuery,
  licenseStatuses: useLicenseStatusesListQuery,
  positions: usePositionsListQuery,
  statuses: useStatusesListQuery,
  termItemTypes: useTermItemTypesListQuery,
  topics: useTopicsListQuery,
  templates: useTemplatesListQuery,
  orgs: useOrgsListQuery,
};

type Lists = typeof lists;

type GetResType<T> = T extends UseQuery<
  QueryDefinition<any, any, any, { list: infer U extends any[]; map: any }, any>
>
  ? { list: U; get: (id: number | null | undefined) => U[number] }
  : never;

type GetArgs<T> = T extends UseQuery<
  QueryDefinition<infer A, any, any, any, any>
>
  ? void extends A
    ? [options?: ListOptions | boolean]
    : [orgIdOrSkip: number | true]
  : never;

type UseList = <LT extends keyof Lists>(
  list: LT,
  ...args: GetArgs<Lists[LT]>
) => GetResType<Lists[LT]>;

export const useList = ((listName, args) => {
  const arg =
    typeof args === "number"
      ? { orgId: args }
      : args === true || (args && args?.skip)
      ? skipToken
      : undefined;
  const { currentData } = (lists[listName] as any)(arg);

  return useMemo(() => {
    const { list = [], map = {} } = currentData || {};
    function get(id: number | null | undefined) {
      if (id === undefined || id === null) return;
      const index = listName === "orgs" ? map[id]?.index : map[id];
      const item = list[index];
      return item;
    }
    return { list, get };
  }, [currentData]);
}) as UseList;

export function useOrgsList(skip = false) {
  const { currentData } = useOrgsListQuery(undefined, { skip });
  return useMemo(() => {
    const { list = [], map = {} } = currentData || {};
    function get(id: number | null | undefined) {
      if (id === undefined || id === null) return;
      const orgMap = map[id || 0] || {};
      const item = list[orgMap.index];
      return item;
    }
    return { list, get };
  }, [!!skip, currentData]);
}

export type ListType<L extends keyof Lists> = GetResType<
  Lists[L]
>["list"][number];

export function useUsersByOrgsList(orgId?: number | null, skip = false) {
  const { currentData } = useOrgsListQuery(skip ? skipToken : undefined);
  return useMemo(() => {
    const { list = [], map = {} } = currentData || {};
    const orgMap =
      map[orgId || 0] ||
      ({
        index: -1,
        users: {},
      } as (typeof map)[0]);
    const org = list[orgMap.index] || { users: [] };
    return {
      list: org.users,
      get(id?: number | null, orInternal = true) {
        if (id === undefined || id === null) return;
        const orgUser = org.users[orgMap.users[id]];
        if (orgUser || !orInternal || orgId === -1) return orgUser;
        const internalOrgMap = map[-1];
        const internalOrg = list[internalOrgMap?.index];
        const internalUser = internalOrg?.users[internalOrgMap?.users?.[id]];
        return internalUser;
      },
    };
  }, [currentData, orgId]);
}

export type ListOptions = {
  skip?: boolean;
  allowDeleted?: AllowDeleted;
};

export function useStatusesList<
  StatusType extends
    | "all"
    | "all_tasks"
    | "session"
    | "task"
    | "internal_task"
    | "custom_work"
    | "quick_question" = "all",
>(type = "all" as StatusType, { allowDeleted = true, skip }: ListOptions = {}) {
  const { list, get } = useList("statuses", skip);
  return useMemo(() => {
    const l = list.filter((s) => {
      if (s.type === "all") return true;
      if (s.type === type) return true;
      if (s.type === "all_tasks" && type !== "session") return true;
      if (s.type !== "session" && type === "all_tasks") return true;
    });
    function getStatusByName<SType extends StatusType = StatusType>(
      internal_name: string,
    ): SType extends "all" | "all_tasks" ? number[] : number {
      const statusesByName = list
        .filter((s) => s.internal_name === internal_name)
        .map((s) => s.id);
      return (
        type === "all" || type === "all_tasks"
          ? statusesByName
          : statusesByName[0]
      ) as SType extends "all" | "all_tasks" ? number[] : number;
    }

    function getStatusByPhase(
      phase: (typeof list)[number]["transition_phase"],
    ) {
      return l.filter((s) => s.transition_phase === phase).map((s) => s.id);
    }

    function getEditableStatuses() {
      return l?.filter((ss) => !ss?.is_automated);
    }

    function getCompleteStatusByPhase() {
      const statuses = getStatusByPhase("done");
      const completeStatuses = new Set([21, 14, 20, 19, 13]);
      const status = statuses.find((s) => completeStatuses.has(s));
      if (!status) {
        throw new Error("Failed to get complete status for" + type);
      }
      return status;
    }

    return {
      statuses: l,
      getStatus: get,
      getStatusByName,
      getStatusByPhase,
      getCompleteStatusByPhase,
      getEditableStatuses,
    };
  }, [list, get, type, allowDeleted]);
}
type AllowDeleted = boolean | number | number[];
