import { Button } from "@mui/material";
import { useState } from "react";
import { ActivityModal } from "../modal/ActivityModal";
import {
  useGetSessionActivitiesQuery,
  useGetOrgSessionActivitiesQuery,
} from "../../state/rtk-query/state/session";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { useOrgId } from "../hooks";

export function SessionActivityButton({ id }: { id?: number }) {
  const orgId = useOrgId();
  const isInternal = useSelector(authSelectors.isInternal);
  const [open, setOpen] = useState(false);
  const hook1 = useGetSessionActivitiesQuery(id ? { id } : skipToken, {
    refetchOnMountOrArgChange: true,
  });
  const hook2 = useGetOrgSessionActivitiesQuery(
    orgId && id ? { id, orgId } : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { data: { activities } = {} } = isInternal ? hook1 : hook2;

  return (
    <>
      <Button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(true);
        }}
        disabled={!activities?.length}
      >
        {activities?.length ? "All activity" : "No activity"}
      </Button>
      <ActivityModal
        id={id}
        open={open}
        setOpen={setOpen}
        activities={activities}
        type="session"
      />
    </>
  );
}
