import { Grid, Button, Stack, Link } from "@mui/material";

import { FormikHelpers, FormikProps } from "formik";
import { yup } from "../../lib";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors, clientActions, uiActions } from "../../state";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { actions } from "../../state/rtk-query/state";
import { useMobile } from "../../themes";
import { useOpenTaskDrawer, useOrgId } from "../hooks";
import { useCreateTaskMediaMutation } from "../../state/rtk-query/state/tasks";
import { useOrgUsersList } from "../hooks/useLists";
import { NameWithAvatar } from "../icons";
import { useSaveMediaMutation } from "../../state/rtk-query/state/media";
import { BaseForm, BaseModal } from "./BaseForm";
import {
  HtmlField,
  SelectField,
  InputField,
  SingleDatePickerField,
} from "./fields";
import { useList, useUsersByOrgsList } from "../hooks/useNewLists";
import { PriorityChip } from "../chips";
import { PropsOf } from "@emotion/react";
import { InlineAttachmentsList } from "../misc";

const { useCreateTaskMutation } = actions;

interface Props {
  open: boolean;
  onClose: () => void;
  sessionId?: number;
  submitAndClose?: boolean;
  orgId?: number;
  type: "task" | "custom_work" | "quick_question" | "internal_task";
  noModal?: boolean;
}

export function AddTaskForm({
  onClose,
  sessionId,
  submitAndClose,
  type,
  open,
  noModal,
  ...rest
}: Props) {
  const orgId = useOrgId(rest.orgId);
  const topics = useList("topics");
  const isMobile = useMobile();
  const userId = useSelector(authSelectors.userId);
  const isInternal = useSelector(authSelectors.isInternal);
  const { orgUsers } = useOrgUsersList(orgId!, !orgId);
  const admireUsers = useUsersByOrgsList(
    -1,
    type !== "internal_task" && !isInternal,
  );
  const openTaskDrawer = useOpenTaskDrawer();

  const [createTask] = useCreateTaskMutation();
  const [saveMedia] = useSaveMediaMutation();
  const [createTaskMedia] = useCreateTaskMediaMutation();

  const [files, setFiles] = useState<File[]>([]);
  const validationSchema = useMemo(
    () =>
      yup.object({
        topic_id: yup.string().required("Topic is required"),
        title: yup.string().required("Title required"),
        description: yup.string().when([], {
          is: () => !files?.length,
          then: yup.string().required("Description is required"),
          otherwise: yup.string().optional().nullable(),
        }),
        client_assignee: yup.number().required("Client Assignee is required"),
        assigned_to: yup.number(),
      }),
    [files],
  );

  const uploadAttachments = useCallback(
    async (taskId?: number) => {
      if (files.length && taskId && orgId) {
        [...files].map(async (file: any) => {
          const { name } = file ?? {};
          const formData = new FormData();
          formData.append("file", file);

          const missingLabel = name.split(".")[0];
          const retFile = await saveMedia({
            body: { file: formData },
            label: missingLabel,
            name: name || missingLabel,
          }).unwrap();

          if (taskId && retFile.id) {
            await createTaskMedia({
              id: taskId,
              orgId,
              body: {
                media_id: retFile.id,
                is_internal: false,
              },
            });
          }
          return;
        });
      }
      setFiles([]);
    },
    [files, orgId],
  );

  const initialValues = {
    topic_id: "" as number | "",
    title: "",
    description: "",
    priority: "normal" as const,
    client_assignee: (!isInternal
      ? userId || ""
      : type === "internal_task"
      ? -1
      : "") as number | "",
    requested_by: null as Date | null,
    assigned_to: type === "internal_task" ? userId : ("" as number | ""),
  };
  const ref = useRef<FormikProps<typeof initialValues>>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isInternal && type === "internal_task") onClose();
  }, [isInternal, type, onClose]);

  const displayNotification = (taskId?: number) => {
    if (taskId) {
      dispatch(
        uiActions.showSuccess(
          <Stack width={"100%"} direction={"row"} alignItems={"center"}>
            Task created
            <Link
              onClick={() => openTaskDrawer(taskId)}
              fontWeight={"600"}
              ml={0.5}
            >
              View task
            </Link>
          </Stack>,
        ),
      );
    }
  };

  const [addAndSave, setAddAndSave] = useState(false);

  const handleSubmit = async (
    {
      client_assignee,
      description,
      priority,
      requested_by,
      title,
      topic_id,
      assigned_to,
    }: typeof initialValues,
    { resetForm }: Pick<FormikHelpers<typeof initialValues>, "resetForm">,
  ) => {
    if (orgId && type) {
      const topicId =
        topic_id ||
        topics.list.find((t) => t.internal_name === "generic_topic")?.id;
      const submit = await createTask({
        orgId,
        body: {
          client_assignee: Number(client_assignee || 0),
          topic_id: topicId || 0,
          reported_by: userId,
          type,
          title,
          priority: priority || "normal",
          description,
          ...(type === "task" && { session_id: sessionId }),
          ...((type === "custom_work" || type === "internal_task") && {
            requested_by_date: requested_by?.toISOString(),
          }),
          ...(type === "internal_task" && assigned_to && { assigned_to }),
        },
      });
      if (!("error" in submit)) {
        const newTaskId = submit.data.id;
        await uploadAttachments(newTaskId);
        if (newTaskId) {
          dispatch(clientActions.setSupportTaskId(newTaskId));
        }
        if (!addAndSave) {
          displayNotification(newTaskId);
          onClose();
        } else {
          resetForm();
          setAddAndSave(false);
        }
      } else {
        onClose();
      }
    }
  };

  const form = (
    <BaseForm
      innerRef={ref}
      onClose={onClose}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      submitButtonName={!submitAndClose ? "Save" : "Submit"}
      additionalButtons={
        !submitAndClose ? (
          <Button
            sx={{
              width: isMobile ? "100%" : "fit-content",
              wordWrap: "none",
              fontWeight: 600,
            }}
            color="primary"
            variant="contained"
            onClick={() => {
              setAddAndSave(true);
              if (ref?.current) ref.current.submitForm();
            }}
            disabled={addAndSave}
          >
            Save and add another
          </Button>
        ) : null
      }
      additionalButtonsPosition="middle"
    >
      {() => {
        return (
          <Grid container rowSpacing={1.5} columnSpacing={2}>
            <SelectField name="topic_id" topLabel items={topics.list} />
            <InputField name="title" topLabel />
            <HtmlField
              name="description"
              topLabel
              md12
              setAttachments={setFiles}
            />
            {(type === "custom_work" || type === "internal_task") && (
              <SingleDatePickerField name="requested_by" topLabel />
            )}
            {type === "quick_question" && (
              <SelectField
                items={["normal", "high", "critical"]}
                name="priority"
                topLabel
                RenderItem={PriorityRenderItem}
              />
            )}
            <SelectField
              name={
                type === "internal_task" ? "assigned_to" : "client_assignee"
              }
              topLabel={type === "internal_task" ? "Assignee" : true}
              items={type === "internal_task" ? admireUsers.list : orgUsers}
              md={true}
              RenderItem={AssigneeRenderItem}
            />
            <Grid item xs={12} md={12}>
              <Stack gap={1} direction={"column"}>
                <InlineAttachmentsList
                  attachments={files}
                  setAttachments={setFiles}
                />
              </Stack>
            </Grid>
          </Grid>
        );
      }}
    </BaseForm>
  );

  if (noModal) {
    return form;
  }

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title={
        type === "task"
          ? "Add Task"
          : type === "custom_work"
          ? "Custom Work"
          : type === "internal_task"
          ? "Internal Task"
          : "Quick Question"
      }
      pcWidth={"40%"}
    >
      {form}
    </BaseModal>
  );
}

function PriorityRenderItem({
  value,
}: {
  value: "normal" | "high" | "critical";
}) {
  return <PriorityChip priority={value} type="external" showPriorityName />;
}

function AssigneeRenderItem({
  item,
}: {
  item: PropsOf<typeof NameWithAvatar>["user"];
}) {
  return <NameWithAvatar user={item} />;
}
