import {
  Typography,
  Stack,
  Button,
  Box,
  Tabs,
  Divider,
  useTheme,
  Tab,
  Link,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  AddIcon,
  CardIcon,
  ListIcon,
  SortableButton,
  TimeEntryModal,
  TimerCard,
  TimerCardHeader,
} from "../../../components";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import {
  addDays,
  differenceInSeconds,
  getDate,
  getDay,
  getMonth,
  intervalToDuration,
  isSameDay,
  startOfWeek,
} from "date-fns";

import { WeekPicker } from "../../../components/inputs";
import { filterBoolean, fixJsFormattedDate, RouterLink } from "../../../lib";
import {
  useGetMyDailySubtotalsQuery,
  useGetMyTimersQuery,
} from "../../../state/rtk-query/state/timer";
import { Emptiable, Loadable } from "../../../components/misc";
import { useMobile } from "../../../themes";
import { useMemoObj, useQuery } from "../../../components/hooks";
import { MainPages } from "..";
import { TimerTable } from "../../../components/tables/TimerTable";
import { DateRange } from "@mui/x-date-pickers-pro";

type ViewType = "cards" | "table";
interface QueryParams {
  open: boolean;
  date?: string | Date;
  dateRange: DateRange<any> | undefined;
  taskId?: number;
  timerId?: number;
  orgId?: number;
  mode?: "edit" | "new";
  view?: ViewType;
}
export const TimerPage = () => {
  const { palette } = useTheme();
  const isMobile = useMobile();
  const [query, setQuery] = useQuery<QueryParams>(
    "date",
    "open",
    "taskId",
    "timerId",
    "orgId",
    "view",
    "dateRange",
  );
  const { date, view, dateRange } = useMemo(() => query, [query]);
  const [expand, setExpand] = useState<number[]>([]);
  const [pickedDate, setPickedDate] = useState(
    date ? new Date(date) : new Date(),
  );
  const tz = useMemo(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone,
    [],
  );
  const { data, isFetching, isSuccess } = useGetMyTimersQuery(
    {
      date: pickedDate.toISOString(),
      tz,
    },
    { refetchOnMountOrArgChange: true },
  );
  const { data: subData } = useGetMyDailySubtotalsQuery(
    {
      date: pickedDate.toISOString(),
      tz,
    },
    { refetchOnMountOrArgChange: true },
  );

  const onOpen = useCallback(() => {
    setQuery({ open: true });
  }, [setQuery]);
  const onClose = useCallback(() => {
    setQuery({
      open: undefined,
      taskId: undefined,
      timerId: undefined,
      mode: undefined,
      orgId: undefined,
    });
  }, [setQuery]);

  const tabValues = useMemoObj([
    { id: 0, label: "Sun" },
    { id: 1, label: "Mon" },
    { id: 2, label: "Tues" },
    { id: 3, label: "Wed" },
    { id: 4, label: "Thurs" },
    { id: 5, label: "Fri" },
    { id: 6, label: "Sat" },
  ]);

  const dayOfWeek = useMemo(
    () => (date ? getDay(new Date(date)) : getDay(pickedDate)),
    [pickedDate, date],
  );

  const tabClicked = useCallback(
    (id: number) => {
      const tabDate = addDays(startOfWeek(pickedDate), id);
      setQuery({
        date: tabDate.toLocaleDateString(),
      });
      setPickedDate(tabDate);
    },
    [pickedDate, setQuery],
  );

  const groupingsMap = useMemo(() => new Map<number, number>(), []);

  data?.rows.forEach((r) => {
    if (r && groupingsMap.has(r?.id)) {
      return;
    } else {
      if (!r?.first_timer_of_group && r?.id) {
        // get the last entry in the map
        const findPrev = [...groupingsMap.values()]
          .reverse()
          .find((v) => v < r?.id);
        if (findPrev) groupingsMap.set(r?.id, findPrev);
      } else {
        if (r?.id && r?.first_timer_of_group) {
          groupingsMap.set(r?.id, r?.first_timer_of_group);
        }
      }
    }
  });

  useEffect(() => {
    if (data?.rows.length) {
      setExpand(data?.rows.map((r) => Number(r?.id ?? 0)));
    }
  }, [data?.rows, query.timerId]);

  useEffect(() => {
    if (!view) setQuery({ view: "cards" });
    if (!date && view === "cards") {
      setQuery({ date: new Date(), view });
    }
    if (view === "cards" && dateRange) setQuery({ dateRange: undefined });
    if (view === "table" && date) setQuery({ date: undefined });
  }, [date, dateRange, setQuery, view]);
  const getTimerGroup = useCallback(
    (id: number) => {
      return (
        [...groupingsMap.entries()]
          .filter(([_k, v]) => v === id)
          .flatMap((t) => data?.rows.find((r) => r?.id === t[0]) ?? [])
          .filter(Boolean) ?? []
      );
    },
    [data?.rows, groupingsMap],
  );
  const timeReviewPage = useMemo(() => MainPages(true).timerReview.path, []);
  const handleView = useCallback(
    (_event: React.MouseEvent<HTMLElement>, newView: ViewType) => {
      if (newView !== null) {
        setQuery({ view: newView });
      }
    },
    [setQuery],
  );
  const hasDateRange = useMemo(
    () => dateRange && !!filterBoolean(dateRange)?.length,
    [dateRange],
  );
  return (
    <>
      <Stack
        direction={isMobile ? "column" : "row"}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        mb={isMobile ? 2 : 4}
        mt={isMobile ? 2 : 0}
        gap={isMobile ? 1 : 0}
      >
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <Typography fontSize="20px" fontWeight="bold">
            Time Entries
          </Typography>
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleView}
            color="info"
            size="small"
          >
            <ToggleButton value="cards">
              <CardIcon />
            </ToggleButton>

            <ToggleButton value="table">
              <ListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          {view === "table" && (
            <SortableButton
              title={"All dates"}
              onChange={(range) => {
                setQuery({
                  dateRange: range[0] && range[1] ? range : undefined,
                  view,
                });
              }}
              isDropDown
              isDate
              currentSort={dateRange as any as Date[]}
              removeTitle={hasDateRange}
            />
          )}
        </Stack>
        <Stack
          direction={isMobile ? "column" : "row"}
          gap={2}
          alignItems={"center"}
        >
          <Link
            to={timeReviewPage}
            component={RouterLink}
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              p: 0.75,
            }}
          >
            Review entries
          </Link>
          <Button
            variant="contained"
            onClick={onOpen}
            sx={{ fontWeight: "600" }}
          >
            <AddIcon style={{ fontSize: "1em" }} /> Manual Entry
          </Button>
        </Stack>
      </Stack>
      {view === "cards" ? (
        <>
          <Stack direction={"column"} width={"100%"} gap={2} mb={3}>
            <WeekPicker pickedDate={pickedDate} setPickedDate={setPickedDate} />

            <Box width={"100%"}>
              <Tabs
                value={dayOfWeek}
                allowScrollButtonsMobile
                variant="scrollable"
              >
                {tabValues.map((tv) => {
                  const day = addDays(
                    startOfWeek(
                      new Date(
                        fixJsFormattedDate(pickedDate.toDateString()) ?? "",
                      ),
                    ),
                    tv.id,
                  );

                  const subtotal =
                    subData?.find((s) =>
                      isSameDay(
                        new Date(fixJsFormattedDate(s?.date ?? "")),
                        day,
                      ),
                    )?.subtotal ?? 0;

                  const duration = intervalToDuration({
                    start: 0,
                    end: subtotal * 60000, // we send from the backend minutes and date-fns parses from milliseconds
                  });
                  const mins = duration?.minutes ?? 0;

                  return (
                    <Tab
                      disableRipple
                      key={tv.id}
                      sx={{
                        width: "14.28%",
                        textAlign: "center",
                        color:
                          dayOfWeek === tv.id
                            ? palette.primary.main
                            : palette.grey[900],
                      }}
                      onClick={() => tabClicked(tv.id)}
                      label={
                        <Stack
                          direction={"column"}
                          sx={{
                            color:
                              dayOfWeek === tv.id
                                ? palette.primary.main
                                : palette.grey[900],
                          }}
                        >
                          <Typography fontWeight={500} fontSize={14}>
                            {tv.label} {getMonth(day) + 1}/{getDate(day)}
                          </Typography>
                          <Typography fontWeight={600} fontSize={18}>
                            {subtotal && duration
                              ? `${duration.hours}:${
                                  !mins ? "00" : mins < 10 ? "0" : ""
                                }${mins || ""}`
                              : `0:00 `}
                          </Typography>
                        </Stack>
                      }
                    />
                  );
                })}
              </Tabs>
              <Divider flexItem />
            </Box>
          </Stack>

          <Loadable isLoading={isFetching}>
            <Emptiable type="timers" isEmpty={isSuccess && !data?.rows?.length}>
              {data?.rows?.map((r, p, arr) => {
                const difGrouping =
                  r?.id &&
                  groupingsMap.get(r.id) !==
                    groupingsMap.get(arr[p + 1]?.id as number);
                return (
                  <Fragment key={r?.id}>
                    <Box>
                      {r?.first_timer_of_group ? (
                        <TimerCardHeader
                          timer={r}
                          expand={expand}
                          setExpand={setExpand}
                          getTimerGroup={getTimerGroup}
                        />
                      ) : null}
                      {
                        <TimerCard
                          timer={r}
                          next={arr[p + 1]}
                          expand={expand}
                        />
                      }
                    </Box>
                    {difGrouping && <Box my={1} />}
                    {arr[p + 1]?.id &&
                    typeof arr[p + 1]?.start_time === "string" &&
                    r?.end_time &&
                    difGrouping &&
                    Math.abs(
                      differenceInSeconds(
                        new Date(arr[p + 1]?.start_time as string),
                        new Date(r?.end_time),
                      ),
                    ) > 0 ? (
                      <Divider>
                        <Typography variant="subtitle1">Timer off</Typography>
                      </Divider>
                    ) : null}
                    {difGrouping && <Box my={1} />}
                  </Fragment>
                );
              })}
            </Emptiable>
          </Loadable>
        </>
      ) : (
        <TimerTable />
      )}
      {!!query.open && <TimeEntryModal open={!!query.open} onClose={onClose} />}
    </>
  );
};
