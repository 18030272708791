import { Stack, Button, Typography } from "@mui/material";
import { FilterButton } from "../buttons/dropdown";
import { useGridStateQuery } from "../hooks/useFilterModel";
import { useList } from "../hooks/useNewLists";
import {
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";

const values = {
  CW: ["Comp CW - Non Billable", "CW - Billable", "Standard CW - Non Billable"],
  Support: ["Comp Sessions", "Sessions", "Tasks"],
};

export const TimerTableToolbar = () => {
  const { filterModel, setFilterItem, setFilters } = useGridStateQuery();
  const orgs = useList("orgs");
  const orgItem = filterModel?.items?.find((i) => i.id === 123);
  const activityItem = filterModel?.items?.find((i) => i.id === "activity");

  const selectedActivity =
    activityItem?.value?.[0] === "Comp CW - Non Billable"
      ? "CW"
      : activityItem?.value?.[0] === "Comp Sessions"
      ? "Support"
      : undefined;

  const selectedOrg = orgs.list.find((o) => o.name === orgItem?.value)?.id;

  return (
    <GridToolbarContainer
      sx={{
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Stack
        direction={"row"}
        overflow={"auto"}
        sx={{
          "::-webkit-scrollbar": { display: "none" },
          scrollbarWidth: "none",
        }}
      >
        <FilterButton
          items={orgs.list}
          getItem={orgs.get}
          selected={selectedOrg}
          setSelected={(v) =>
            setFilterItem(
              123,
              v
                ? {
                    field: "org_name",
                    operator: "equals",
                    value: orgs.get(v)?.name,
                  }
                : undefined,
            )
          }
          withSearch
        >
          Org
        </FilterButton>
        <FilterButton
          items={["CW", "Support"]}
          selected={selectedActivity}
          setSelected={(v) => {
            setFilterItem(
              "activity",
              v
                ? { field: "timer_type", operator: "isAnyOf", value: values[v] }
                : undefined,
            );
          }}
        >
          Activity
        </FilterButton>
      </Stack>
      <Stack
        direction={"row"}
        gap={1}
        justifyContent={"flex-end"}
        alignContent={"center"}
      >
        {!!(selectedOrg || selectedActivity) && (
          <Button
            variant="text"
            color="info"
            sx={{ minWidth: "fit-content" }}
            size="small"
            onClick={() => {
              setFilters(
                filterModel.items.filter(
                  (i) => i.id !== 123 && i.id !== "activity",
                ),
              );
            }}
          >
            <Typography fontSize={16} fontWeight={"500"}>
              Reset filters
            </Typography>
          </Button>
        )}
        <GridToolbarExport
          slotProps={{ button: { color: "info" } }}
          csvOptions={{ disableToolbarButton: true }}
          printOptions={{ disableToolbarButton: true }}
        />
      </Stack>
    </GridToolbarContainer>
  );
};
