import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../types";

const _state = (state: AppState) => state.timer;
const _currentTimer = createSelector(_state, (state) => state.timer);

export const timerSelectors = {
  currentTimer: _currentTimer,
  taskOrgId(state: AppState) {
    return state.timer.timer.taskOrgId;
  },
  initializing(state: AppState) {
    return state.timer.timer.initializing;
  },
  timerId(state: AppState) {
    return state.timer.timer.id;
  },
  timerState(state: AppState) {
    return state.timer.timer.state;
  },
  inferredOrgId(state: AppState) {
    return state.timer.timer.inferredOrgId;
  },
  cancelledSwitch(state: AppState) {
    return state.timer.timer.cancelledSwitch;
  },
  manualStopped(state: AppState) {
    return state.timer.timer.manualStopped;
  },
  timerIsTransitioning(state: AppState) {
    return (
      (state.timer.timer.cancelledSwitch === false &&
        state.timer.timer.state === "switching") ||
      state.timer.timer.state === "stopping"
    );
  },
};
