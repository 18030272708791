import { useCallback, useMemo } from "react";
import { BaseForm, BaseModal, initValue } from "../forms";
import {
  SelectField,
  InputField,
  AutocompleteField,
  RadioGroupField,
  SingleDatePickerField,
  SingleTimeField,
} from "../forms/fields";
import { useOrgsList, useActivityTypesList } from "../hooks/useLists";
import { Grid } from "@mui/material";
import { yup } from "../../lib";
import { format, isAfter, isBefore } from "date-fns";
import {
  CreateTimerManualApiArg,
  GetTimerApiResponse,
  useCreateTimerManualMutation,
  useDeleteTimerMutation,
  useUpdateTimerManualMutation,
} from "../../state/rtk-query/state/timer";
import { useSelector } from "react-redux";
import { authSelectors, timerSelectors } from "../../state";
import { ConfirmDeleteDialog } from "../tables";
import { useGetCwTasksQuery } from "../../state/rtk-query/state/tasks";

interface Props {
  open: boolean;
  onClose: () => void;
  timer?: GetTimerApiResponse;
  orgId?: number;
  taskId?: number;
}

const validationSchema = yup.object({
  org_id: yup.number().required("Organization is required"),
  start_time: yup.date().when(["entry_type"], {
    is: (et: any) => et === "time",
    then: yup
      .date()
      .test("start_time_test", (value, { createError, parent, path }) => {
        if (value) {
          const { end_time } = parent;
          if (isAfter(new Date(value), new Date(end_time))) {
            return createError({
              path,
              message: "Start time must be before the end time",
            });
          }
        }
        return true;
      })
      .required("Start time is required"),
    otherwise: yup.date().nullable().optional(),
  }),
  end_time: yup.date().when(["entry_type"], {
    is: (et: any) => et === "time",
    then: yup
      .date()
      .test("start_time_test", (value, { createError, path, parent }) => {
        if (value) {
          const { start_time } = parent;
          if (isBefore(new Date(value), new Date(start_time))) {
            return createError({
              path,
              message: "End time must be later than start time",
            });
          }
        }
        return true;
      })
      .required("End time is required"),
    otherwise: yup.date().nullable().optional(),
  }),
  duration: yup.number().when(["entry_type"], {
    is: (et: any) => et === "duration",
    then: yup.number().min(0).required("Duration is required"),
    otherwise: yup.number().nullable().optional(),
  }),
  date: yup.date().required("Date is required"),
  activity_type_id: yup
    .number()
    .min(1, "Type is required")
    .required("Type is required"),
  task: AutocompleteField.schema.when(["is_cw"], {
    is: (is_cw?: boolean) => !!is_cw,
    then: AutocompleteField.schema
      .shape({ id: yup.number().required("Task is required") })
      .required("Task is required"),
    otherwise: AutocompleteField.schema
      .shape({ id: yup.number().optional().nullable() })
      .nullable()
      .optional()
      .required(false),
  }),
  notes: yup.string().nullable().optional(),
});

export const BaseTimeEntryModal = ({
  open,
  onClose,
  timer,
  orgId,
  taskId,
}: Props) => {
  const isInternal = useSelector(authSelectors.isInternal);
  const isCW = useSelector(authSelectors.isCustomWorkUser);
  const { initializing } = useSelector(timerSelectors.currentTimer);
  const { orgs } = useOrgsList(!isInternal);
  const { activityTypes } = useActivityTypesList();

  const [createTimer] = useCreateTimerManualMutation();
  const [updateTimer] = useUpdateTimerManualMutation();
  const [deleteTimer] = useDeleteTimerMutation();
  const { currentData: CWTasks } = useGetCwTasksQuery(undefined, {
    skip: !isInternal,
  });

  const cwBillable = useMemo(
    () => activityTypes.find((a) => a.internal_name === "custom_work")?.id,
    [activityTypes],
  );

  const isCWActivity = useCallback(
    (activity_type_id?: number) => {
      return activityTypes
        .find((c) => c.id === activity_type_id)
        ?.internal_name.includes("custom_work");
    },
    [activityTypes],
  );

  const initActivityTypeId = initValue(
    timer?.activity_type_id || (isCW ? cwBillable : undefined),
  );

  const initialValues = useMemo(
    () => ({
      entry_type: timer?.duration ? "duration" : "time",
      is_cw: initActivityTypeId ? isCWActivity(initActivityTypeId) : false,
      org_id: initValue(timer?.org_id || orgId),
      duration: initValue(timer?.duration),
      start_time: timer?.start_time ? new Date(timer?.start_time) : undefined,
      end_time: timer?.end_time ? new Date(timer?.end_time) : new Date(),
      date: timer?.start_time
        ? new Date(timer?.start_time)
        : timer?.duration_date
        ? new Date(timer?.duration_date)
        : new Date(),
      activity_type_id: initActivityTypeId,
      task:
        timer?.task_id || taskId
          ? CWTasks?.rows?.find(
              (t) => t.id === timer?.task_id || t.id === taskId,
            )
          : null,
      notes: initValue(timer?.notes),
    }),
    [
      timer?.duration,
      timer?.org_id,
      timer?.start_time,
      timer?.end_time,
      timer?.duration_date,
      timer?.task_id,
      timer?.notes,
      initActivityTypeId,
      isCWActivity,
      orgId,
      taskId,
      CWTasks?.rows,
    ],
  );

  const onSubmit = async ({
    entry_type: entryType,
    is_cw,
    activity_type_id,
    duration,
    date,
    notes,
    end_time,
    start_time,
    org_id,
    task,
  }: typeof initialValues) => {
    const vals: CreateTimerManualApiArg["body"] = {
      org_id,
      activity_type_id,
      notes,
      task_id: task?.id,
    };
    vals.date = format(new Date(date), "yyyy-MM-dd");
    if (entryType === "duration") {
      vals.duration = duration;
    } else {
      vals.end_time = end_time?.toISOString();
      vals.start_time = start_time?.toISOString();
    }
    if (is_cw && !task?.id) {
      return;
    }
    if (timer?.id && initializing !== true) {
      console.log("updateTimer", { vals });
      await updateTimer({
        id: timer.id,
        body: vals,
      });
    } else {
      await createTimer({
        body: vals,
      });
    }
    onClose();
  };
  const disableEditing =
    timer?.status && ["old", "billed", "billing"].includes(timer.status);
  const setTasksFilteredCB = useCallback(
    (
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
      ) => Promise<any>,
      values: typeof initialValues,
    ) => {
      let tIdInList = false;
      const filtered = (CWTasks?.rows ?? [])
        .filter((c) => c.org_id === Number(values.org_id))
        ?.map((c) => {
          if (c.id === values.task?.id) {
            tIdInList = true;
          }
          return {
            id: c.id,
            name: c?.name,
          };
        });
      const newActivityIsCw = isCWActivity(values?.activity_type_id);
      if (newActivityIsCw !== values.is_cw) {
        setFieldValue("is_cw", newActivityIsCw);
      }

      if (tIdInList && newActivityIsCw && values?.task && !values?.task?.id) {
        setFieldValue("task", tIdInList ? values.task : null);
      }
      return filtered;
    },
    [CWTasks?.rows, isCWActivity],
  );
  return (
    <BaseModal
      open={open}
      title={`${timer ? "Edit" : "Add"} manual entry`}
      onClose={onClose}
    >
      <BaseForm
        onClose={onClose}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        disableEditing={!!disableEditing}
        disableEditingReason={"This timer has already been billed"}
        additionalButtons={
          timer?.id && !disableEditing ? (
            <ConfirmDeleteDialog
              titleText="Delete"
              buttonText="Delete"
              buttonColor="primary"
              handleConfirm={() => {
                deleteTimer({ id: timer.id });
                onClose();
              }}
            />
          ) : null
        }
      >
        {({ setFieldValue, values }) => {
          const cwTasksFiltered = setTasksFilteredCB(setFieldValue, values);

          return (
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <RadioGroupField
                md12
                name="entry_type"
                items={[
                  { id: "time", name: "Start and end times" },
                  { id: "duration", name: "Duration" },
                ]}
                row
              />
              <SelectField
                items={orgs}
                name="org_id"
                topLabel="Organization"
                readOnly={!timer && !!orgId}
              />
              <SelectField
                items={activityTypes}
                name="activity_type_id"
                topLabel="Type"
              />
              <Grid item xs={12}>
                <SingleDatePickerField name="date" topLabel md12 />
              </Grid>

              {values.entry_type === "time" && (
                <SingleTimeField name="start_time" topLabel="Start time" />
              )}
              {values.entry_type === "time" && (
                <SingleTimeField name="end_time" topLabel="End time" />
              )}
              {values.entry_type === "duration" && (
                <InputField
                  type="number"
                  name="duration"
                  topLabel="Duration minutes"
                />
              )}

              {values?.is_cw && (!!timer || !taskId) && (
                <AutocompleteField
                  topLabel
                  items={cwTasksFiltered}
                  name="task"
                  md12
                />
              )}
              <InputField name="notes" topLabel md12 multiline />
            </Grid>
          );
        }}
      </BaseForm>
    </BaseModal>
  );
};
