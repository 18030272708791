import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { actions } from "../rtk-query/state/timer";
import { actions as taskActions } from "../rtk-query/state/tasks";

export interface Timer {
  id?: number;
  orgId?: number;
  initializing: boolean;
  state: "inactive" | "active" | "stopped" | "stopping" | "switching";
  taskOrgId?: number;
  inferredOrgId?: number;
  cancelledSwitch?: boolean;
  manualStopped?: boolean;
  midSwitch?: boolean;
  customWork?: {
    taskId?: number;
    taskCode?: string;
    taskTitle?: string;
  };
}
export interface TimerState {
  timer: Timer;
}

const initialState: TimerState = {
  timer: {
    id: undefined,
    orgId: undefined,
    initializing: true,
    state: "inactive",
    taskOrgId: undefined,
    cancelledSwitch: false,
    manualStopped: false,
    midSwitch: false,
    customWork: undefined,
  },
};

export const timer = {
  ...createSlice({
    name: "timer",
    initialState,
    reducers: {
      setTimer(state, { payload }: PayloadAction<Timer | undefined>) {
        if (!payload) {
          return;
        }
        const {
          customWork,
          id,
          initializing,
          orgId,
          state: payloadState,
        } = payload;
        state.timer.id = id;
        state.timer.orgId = orgId;
        state.timer.initializing = initializing;
        state.timer.state = payloadState;
        state.timer.customWork = customWork;
      },
      setTimerId(state, { payload }: PayloadAction<number | undefined>) {
        state.timer.id = payload;
      },
      setTimerOrgId(state, { payload }: PayloadAction<number | undefined>) {
        state.timer.orgId = payload;
      },
      setTimerState(
        state,
        { payload }: PayloadAction<TimerState["timer"]["state"]>,
      ) {
        state.timer.state = payload;
      },
      stopTimer(state, { payload }: PayloadAction<boolean>) {
        if (payload) {
          state.timer.manualStopped = payload;
        }
        state.timer.state = "stopped";
      },
      setTimerCw(
        state,
        { payload }: PayloadAction<Timer["customWork"] | undefined>,
      ) {
        state.timer.customWork = payload;
      },
      setTimerInferredOrgId(
        state,
        { payload }: PayloadAction<number | undefined>,
      ) {
        state.timer.inferredOrgId = payload;
      },
      resetTimer(state, {}: PayloadAction<undefined>) {
        // state.timer.inferredOrgId = undefined;
        state.timer.taskOrgId = undefined;
        state.timer.orgId = undefined;
      },
      setTimerCancelledSwitch(state, { payload }: PayloadAction<boolean>) {
        state.timer.cancelledSwitch = payload;
      },
      setManualStopped(state, { payload }: PayloadAction<boolean>) {
        state.timer.manualStopped = payload;
      },
      setTimerMidSwitch(state, { payload }: PayloadAction<boolean>) {
        state.timer.midSwitch = payload;
      },
    },
    extraReducers: (builder) => {
      builder.addMatcher(
        actions.endpoints.getMyActiveTimer.matchFulfilled,
        (state, { payload }) => {
          console.log("getMyActiveTimer", { payload });
          const id = payload?.id || 0;
          state.timer.id = id;
          state.timer.initializing = false;
          if (state.timer.state === "active" && id === 0) {
            state.timer.state = "inactive";
          } else if (id !== 0 && state.timer.state !== "active") {
            state.timer.state = "active";
          }
          if (payload?.org_id && state.timer.orgId !== payload?.org_id) {
            state.timer.orgId = payload.org_id;
            state.timer.inferredOrgId = payload.org_id;
          }
          state.timer.customWork = payload?.task_id
            ? {
                taskId: payload?.task_id,
                taskTitle: payload?.task_title || "",
              }
            : initialState.timer.customWork;
        },
      ),
        builder.addMatcher(
          taskActions.endpoints.getTask.matchFulfilled,
          (state, { payload }) => {
            console.log("getTask", { payload });
            state.timer.taskOrgId = payload.org_id;
            state.timer.inferredOrgId = payload.org_id;
          },
        );
    },
  }),
  persist: false,
};
