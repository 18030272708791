import { useCallback, useState } from "react";
import { PickersShortcutsItem } from "@mui/x-date-pickers";
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { DateRange } from "@mui/lab/DateRangePicker";
import { useMobile } from "../../../themes";
import { DropDownShell } from "./DropDownShell";
import { TitleWithValues } from "./TitleWithValues";

export namespace DateFilterButton {
  export type Props = {
    value?: DateRange<string | Date>;
    setValue: (value?: [Date, Date] | null) => void;
    children: string;
  };
}

export const DateFilterButton = ({
  children: title,
  value,
  setValue,
}: DateFilterButton.Props) => {
  const isMobile = useMobile();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | undefined>(
    null,
  );

  const onCalendarChange = useCallback(
    (values: DateRange<any>) => {
      if (values?.filter(Boolean).length === 2) {
        setValue(values);
        setAnchorEl(null);
      } else {
        setValue(undefined);
      }
    },
    [setValue],
  );

  const today = new Date();
  const shortcutsItems: PickersShortcutsItem<DateRange<any>>[] = [
    {
      label: "This Week",
      getValue: () => {
        return [startOfWeek(today), endOfWeek(today)];
      },
    },
    {
      label: "Last Week",
      getValue: () => {
        const prevWeek = new Date().setDate(today.getDate() - 7);
        return [startOfWeek(prevWeek), endOfWeek(prevWeek)];
      },
    },
    {
      label: "Last 7 Days",
      getValue: () => {
        return [new Date(new Date().setDate(today.getDate() - 7)), today];
      },
    },
    {
      label: "Current Month",
      getValue: () => {
        return [startOfMonth(today), endOfMonth(today)];
      },
    },
    {
      label: "Next Month",
      getValue: () => {
        const startOfNextMonth = new Date(
          endOfMonth(today).setDate(endOfMonth(today).getDate() + 1),
        );
        return [startOfNextMonth, endOfMonth(startOfNextMonth)];
      },
    },
    { label: "Reset", getValue: () => [null, null] },
  ];

  return (
    <DropDownShell
      type="popover"
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      button={
        <TitleWithValues
          title={title}
          value={
            value?.[0] && value?.[1]
              ? value.map((d) => new Date(d!).toLocaleDateString()).join(" - ")
              : undefined
          }
        />
      }
    >
      <StaticDateRangePicker
        slotProps={{
          shortcuts: {
            items: isMobile ? undefined : shortcutsItems,
          },
          actionBar: { actions: [] },
        }}
        calendars={isMobile ? 1 : 2}
        onChange={onCalendarChange}
        value={value || [null, null]}
      />
    </DropDownShell>
  );
};
