/**
 * @file this listener should only exist in the TimerBar.tsx file
 */
import { useDispatch, useSelector } from "react-redux";
import { authSelectors, timerActions, timerSelectors } from "../../state";
import {
  useGetTimerQuery,
  useUpdateTimerMutation,
} from "../../state/rtk-query/state/timer";
import { useCallback, useEffect, useMemo, useState } from "react";

import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useActivityTypesList } from "./useLists";
// import { differenceInMinutes, differenceInSeconds } from "date-fns";
// import { NODE_ENV, VITE_TIMER_RESET_MINUTES } from "../../config";
import { MainPages } from "../../pages";
import { matchPath } from "react-router-dom";
import { useStartTimer, useStopTimer } from ".";
// import { SocketMessage, useRefetchSocket } from "../providers";
import { useLocation } from "../../lib/routing/Navigation";

export const useTimerListener = () => {
  console.log("useTimerListener be called again");
  const { query, pathname } = useLocation();
  const dispatch = useDispatch();
  const isInternal = useSelector(authSelectors.isInternal);
  const isConsultant = useSelector(authSelectors.isConsultant);
  const isCustomWorkUser = useSelector(authSelectors.isCustomWorkUser);
  const isIt = useSelector(authSelectors.isIT);
  const {
    id: timerId,
    orgId: timerOrgId,
    state: timerState,
    cancelledSwitch,
    initializing,
  } = useSelector(timerSelectors.currentTimer);
  const taskOrgId = useSelector(timerSelectors.taskOrgId);
  const inferredOrgId = useSelector(timerSelectors.inferredOrgId);
  const manualStopped = useSelector(timerSelectors.manualStopped);

  const isSessionPage = useMemo(
    () => matchPath(MainPages(true).session.path, pathname),
    [pathname],
  );
  // const shouldRefetch = (d: SocketMessage) => {
  //   if (d.tag === "timer" && d.message === "stop" && d.entity_id === timerId) {
  //     dispatch(timerActions.setTimerStateAction("stopped"));
  //   }
  // };

  // useRefetchSocket({ tag: "timer", cb: shouldRefetch });

  const { getActivityId } = useActivityTypesList();

  const activity_type_id = getActivityId(
    isConsultant
      ? "tasks"
      : isCustomWorkUser
      ? "custom_work"
      : isIt
      ? "it"
      : isSessionPage
      ? "sessions"
      : undefined,
  );

  const [updateTimer] = useUpdateTimerMutation();
  const startTimer = useStartTimer(activity_type_id);
  const stopTimer = useStopTimer();
  // const token = useSelector(authSelectors.authToken);
  const [pageLoadType, setPageLoadType] = useState<
    PerformanceNavigationTiming["type"] | undefined
  >();

  const { data } = useGetTimerQuery(
    isInternal && timerId ? { id: timerId } : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  // const options = useMemo(
  //   () => ({
  //     headers: {
  //       authorization: `Bearer ${token}`,
  //     },
  //     keepalive: true, //https://stackoverflow.com/a/67644011
  //   }),
  //   [token],
  // );

  const createTimer = useCallback(async () => {
    if (!isInternal || cancelledSwitch) return;
    console.log("CREATE TIMER", { inferredOrgId });
    if (inferredOrgId && startTimer) {
      await startTimer({ org_id: inferredOrgId });
    }
  }, [isInternal, cancelledSwitch, inferredOrgId, startTimer]);

  // const pathPrefix = "/api/V1/timer";

  // const tabSwitched = useCallback(
  //   (_e?: Event, endTime = false) => {
  //     const org =
  //       cancelledSwitch && stayOnOrgId
  //         ? stayOnOrgId
  //         : inferredOrgId === timerOrgId
  //         ? timerOrgId
  //         : undefined;

  //     if (
  //       (document.visibilityState === "visible" || endTime) &&
  //       timerId &&
  //       org &&
  //       timerState === "active"
  //     ) {
  //       const end = endTime ? new Date().toISOString() : null;
  //       fetch(pathPrefix + `/${timerId}`, {
  //         method: "put",
  //         ...options,
  //         headers: {
  //           "Content-Type": "application/json",
  //           ...options.headers,
  //         },
  //         body: JSON.stringify({
  //           end_time: end,
  //           org_id: org,
  //         }),
  //       });
  //       if (!endTime) refetch();
  //     }
  //   },
  //   [
  //     cancelledSwitch,
  //     inferredOrgId,
  //     options,
  //     refetch,
  //     stayOnOrgId,
  //     timerId,
  //     timerOrgId,
  //     timerState,
  //   ],
  // );

  // const pageClosed = useCallback((_e?: PageTransitionEvent) => {
  //   console.log("Running page closed");
  //   tabSwitched(undefined, true);
  //   const org = cancelledSwitch && stayOnOrgId ? stayOnOrgId : timerOrgId;
  //   fetch(pathPrefix + `/open-tabs/${org || 0}?tab_count_direction=decrement`, {
  //     method: "post",
  //     ...options,
  //   });
  // }, []);

  // useEffect(() => {
  //   if (!isInternal) return;
  //   // https:stackoverflow.com/a/73062712 This will handle reload/tab close
  //   window.addEventListener("pagehide", pageClosed);

  //   return () => {
  //     if (!isInternal) return;
  //     return window.removeEventListener("pagehide", pageClosed);
  //   };
  // }, []);

  useEffect(() => {
    if (!isInternal) return;
    const pageType = window.performance.getEntriesByType(
      "navigation",
    )[0] as PerformanceNavigationTiming; // https://stackoverflow.com/a/53307588
    setPageLoadType(pageType.type);
  }, [isInternal]);

  useEffect(() => {
    if (initializing === true) return;
    const isStopped = timerState === "inactive" || timerState === "stopped";
    const orgPath = matchPath(MainPages(true).organization.path, pathname);
    const taskQuerySet = !!query.task;
    if (orgPath && isStopped) {
      console.log("org path is set");
      const org_id = inferredOrgId || orgPath.params.id;
      startTimer({ org_id: Number(org_id) }).catch(console.error);
    } else if (taskQuerySet && isStopped && taskOrgId) {
      startTimer({ org_id: Number(taskOrgId), task_id: query.task }).catch(
        console.error,
      );
      console.log("query.task is defined");
    } else if (
      timerState === "active" &&
      !taskQuerySet &&
      !orgPath &&
      !cancelledSwitch
    ) {
      console.log(
        "Not query.task is defined and not org path and timer is active",
      );
      stopTimer().catch(console.error);
    }

    if (
      inferredOrgId !== timerOrgId &&
      timerState === "active" &&
      !manualStopped &&
      !cancelledSwitch
    ) {
      console.log("Switching since inferredOrgId !== timerOrgId");
      dispatch(timerActions.setTimerStateAction("switching"));
    }

    console.log("PATH CHANGE", {
      taskOrgId,
      pathname,
      task: query.task,
      startTimer,
      timerState,
      inferredOrgId,
      check:
        query.task === undefined &&
        matchPath(MainPages(true).organization.path, pathname),
    });
  }, [
    pathname,
    inferredOrgId,
    timerState,
    timerOrgId,
    query.task,
    initializing,
    dispatch,
    taskOrgId,
    startTimer,
    stopTimer,
    cancelledSwitch,
    manualStopped,
  ]);

  const updateTimerInfo = useCallback(async () => {
    if (!data) return;
    const org = timerOrgId || data?.org_id;
    console.log("UPDATE TIMER #####", {
      id: timerId,
      body: {
        end_time: null,
        org_id: org,
        activity_type_id,
      },
    });
    if (timerId && org && timerId === data?.id && initializing !== true) {
      await updateTimer({
        id: timerId,
        body: {
          end_time: null,
          org_id: org,
          activity_type_id,
        },
      });

      dispatch(timerActions.setTimerOrgIdAction(org));
    }
  }, []);

  // if one tab is closed but the next is still on the same org
  // useEffect(() => {
  //   if (document.visibilityState === "visible") {
  //     tabSwitched();
  //   }
  // }, [document.visibilityState]);

  const handleCloseAndReopenOrg = useCallback(() => {
    if (initializing === true) {
      return;
    }
    if (data && data.end_time && data.id === timerId) {
      // we should clear timer state if the timer was stopped longer than VITE_TIMER_RESET_MINUTES just like BE
      // if (
      //   NODE_ENV === "development"
      //     ? differenceInSeconds(new Date(), new Date(data.end_time)) >= 10
      //     : differenceInMinutes(new Date(), new Date(data.end_time)) >=
      //       VITE_TIMER_RESET_MINUTES
      // ) {
      //   return createTimer();
      // } else {
      //   return updateTimerInfo();
      // }
    } else {
      if (timerState !== "active" && (!data || (data && data.id === 0))) {
        return createTimer();
      } else {
        return updateTimerInfo();
      }
    }
  }, []);

  // Handle "active" state, "switch" state is handled in TimerBar.tsx
  useEffect(() => {
    if (initializing === true) {
      return;
    }
    if (
      !isInternal ||
      isCustomWorkUser ||
      (timerState && timerState !== "active") ||
      cancelledSwitch
    ) {
      return;
    }

    (async () => {
      if (pageLoadType === "navigate" || !timerId) {
        await handleCloseAndReopenOrg();
      } else {
        if (pageLoadType === "reload" && timerId === data?.id) {
          await handleCloseAndReopenOrg();
        } else {
          if (timerId && timerId === data?.id) {
            await updateTimerInfo();
          } else {
            await createTimer();
          }
        }
      }
    })();
  }, []);
};
